/*-------------------------------------------------------------------------------------------------
Page Template: About
/*-----------------------------------------------------------------------------------------------*/
	#page-about{
		.content{
			overflow: hidden;

			> .section{
				position: relative;
				display: flex;
				flex-direction: column;
				opacity: 0;
				margin: 30px 0 0;
				transition: 
					opacity $animation-transition ease-in,
					transform $animation-transition ease-in;

				// Positioning 'off-screen'
				&.left{transform: translateX(-100%);}
				&.right{
					transform: translateX(100%);
					align-items: flex-end;

					&:after{
						left: 75%;
					}
				}
				& + .section{margin-top:50px;}

				h3{
					margin-left: 30px;
					margin-right: 30px;
				}

				// Line
				&:after{
					content: '';
					position: absolute;
					width: 3px;
					height: 92px;
					bottom:-92px;
					left: 25%;
					background: var(--theme-primary-dark);
					transform: scaleY(0);
					transform-origin: top;
					transition: 
						background-color $animation-transition ease-in,
						transform 200ms $animation-transition linear;
				}
				&.last:after{display: none;}

				// Animation overrides
				&.animateIn{
					opacity: 1;
					transform: translateX(0);

					&:after{transform: scaleY(1);}
				}

				.comp-text{
					margin-left: 30px;
					margin-right: 30px;
				}
			}
		}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Tablet
			@include media-query(tablet){
				// Achievements
				.comp-achievements{
					ul{
						li{
							padding: 8px 5px 8px 40px;

							h6{font-size: 12px;}
							p{font-size: 10px;}

							.icon{
								transform-origin: top left;
								transform: scale(.8);
								left:5px;
							}
						}
					}
				}

				.content{
					> .section {
						margin: 30px 0 0;

						h3{
							margin-left: 15px;
							margin-right: 15px;
						}

						> *{
							&.comp-text{
								margin-left: 15px;
								margin-right: 15px;

								&:before,
								&.tall:after{
									width: 18px;
									left: -18px;
								}
								&.right:before,
								&.right.tall:after{
									width: 18px;
									left: auto;
									right: -18px;
								}
							}
						}
					}
				}
			}

			// Phablet and Down
			@include media-query(phablet-and-down){
				// Achievements
				.comp-achievements{
					max-width: 300px;
				}

				.content{
					> .section{
						margin: 30px 0 0;

						h3{
							margin-left: 15px;
							margin-right: 15px;
						}

						> *{
							&.comp-text{
								margin-left: 15px;
								margin-right: 15px;

								&:before,
								&.tall:after{
									width: 18px;
									left: -18px;
								}
								&.right:before,
								&.right.tall:after{
									width: 18px;
									left: auto;
									right: -18px;
								}
							}
						}
					}
				}
			}

			// Phone
			@include media-query(phone){
				.content{
					> .section{
						> *{
							&.comp-text{
								margin-left: 10px;
								margin-right: 10px;

								&:before,
								&.tall:after{
									width: 13px;
									left: -13px;
								}
								&.right:before,
								&.right.tall:after{
									width: 13px;
									left: auto;
									right: -13px;
								}
							}
						}
					}
				}
			}
	}

	/* Aniamtions -------------------------------------------------------------------------------*/
		@keyframes animateAboutSection(){
			100%{
				opacity: 1;
				transform: translateX(0);
			}
		}
