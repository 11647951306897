/*-------------------------------------------------------------------------------------------------
Sitelines
/*-----------------------------------------------------------------------------------------------*/
	html:not(.noSiteLines){
		#wrapper:before, // Left Line
		#template:before, // Left Line(fades out)
		#wrapper:after, // Right Line 
		#template:after, // Right Line (fades out)
		#content:before, // Mid Line
		#content:after{ // Mid Line (fades out)
			content: '';
			position: fixed;
			width: 3px;
			top: 0;
			left:50%;
			bottom:0;
			display: block;
			z-index: 10;
			background: var(--theme-primary-dark);
			background: linear-gradient(to bottom,
				transparent 0,
				transparent 3px,
				rgba(var(--theme-primary-dark-rgb), 1) 3px,
				rgba(var(--theme-primary-dark-rgb), 1) 6px,
				transparent 6px,
				transparent 9px,
				rgba(var(--theme-primary-dark-rgb), 1) 9px,
				rgba(var(--theme-primary-dark-rgb), 1) 12px,
				transparent 12px,
				transparent 15px,
				rgba(var(--theme-primary-dark-rgb), 1) 15px,
				rgba(var(--theme-primary-dark-rgb), 1) calc(100% - 15px),
				transparent calc(100% - 15px),
				transparent calc(100% - 12px),
				rgba(var(--theme-primary-dark-rgb), 1) calc(100% - 12px),
				rgba(var(--theme-primary-dark-rgb), 1) calc(100% - 9px),
				transparent calc(100% - 9px),
				transparent calc(100% - 6px),
				rgba(var(--theme-primary-dark-rgb), 1) calc(100% - 6px),
				rgba(var(--theme-primary-dark-rgb), 1) calc(100% - 3px),
				transparent calc(100% - 3px),
				transparent 100%
			);
			transition: top $animation-transition linear;
		}

		// Fade in lines (i.e. previous theme colors)
		#template:before,
		#template:after,
		#content:after{
			opacity: 0;
			background: var(--theme-prev-primary-dark);
			background: linear-gradient(to bottom,
				transparent 0,
				transparent 3px,
				rgba(var(--theme-prev-primary-dark-rgb), 1) 3px,
				rgba(var(--theme-prev-primary-dark-rgb), 1) 6px,
				transparent 6px,
				transparent 9px,
				rgba(var(--theme-prev-primary-dark-rgb), 1) 9px,
				rgba(var(--theme-prev-primary-dark-rgb), 1) 12px,
				transparent 12px,
				transparent 15px,
				rgba(var(--theme-prev-primary-dark-rgb), 1) 15px,
				rgba(var(--theme-prev-primary-dark-rgb), 1) calc(100% - 15px),
				transparent calc(100% - 15px),
				transparent calc(100% - 12px),
				rgba(var(--theme-prev-primary-dark-rgb), 1) calc(100% - 12px),
				rgba(var(--theme-prev-primary-dark-rgb), 1) calc(100% - 9px),
				transparent calc(100% - 9px),
				transparent calc(100% - 6px),
				rgba(var(--theme-prev-primary-dark-rgb), 1) calc(100% - 6px),
				rgba(var(--theme-prev-primary-dark-rgb), 1) calc(100% - 3px),
				transparent calc(100% - 3px),
				transparent 100%
			);
			z-index: 11;
		}

		// Left lines
		#wrapper:before,
		#template:before{
			transform: translate(-($container-large * .5) - 3);
		}

		// Right lines
		#wrapper:after,
		#template:after{
			transform: translate(($container-large * .5));
		}

		// Mid Lines
		#content{
			&:before,
			&:after{transform: translate(-($container-large * .5) + 300);}
		}

		/* Responsive Changes -------------------------------------------------------------------*/

			// Desktop
			@include media-query(desktop){
				#wrapper:before,
				#template:before{transform: translate(-($container-desktop * .5) - 3);}
				#wrapper:after,
				#template:after{transform: translate(($container-desktop * .5));}

				#content{
					&:before,
					&:after{transform: translate(-($container-desktop * .5) + 300);}
				}
			}

			// Tablet
			@include media-query(tablet){
				#wrapper:before,
				#template:before{transform: translate(-($container-tablet * .5) - 3);}
				#wrapper:after,
				#template:after{transform: translate(($container-tablet * .5));}

				#content{
						&:before,
						&:after{transform: translate(-($container-tablet * .5) + 250);}
					}
			}

			// Phablet
			@include media-query(phablet-and-down){
				#wrapper:before, // Left Line
				#template:before, // Left Line(fades out)
				#wrapper:after, // Right Line 
				#template:after, // Right Line (fades out)
				#content:before, // Mid Line
				#content:after{ // Mid Line (fades out)
					top: 70px !important;
				}
				
				#wrapper:before,
				#template:before{
					left: 15px;
					transform: none;
				}
				#wrapper:after,
				#template:after{
					left: auto;
					right: 15px;
					transform: none;
				}

				#content{
					&:before,
					&:after{
						display: none;
					}
				}
			}

			// Phone
			@include media-query(phone){
				#wrapper:before,
				#template:before{
					left: 5px;
					transform: none;
				}
				#wrapper:after,
				#template:after{
					left: auto;
					right: 5px;
					transform: none;
				}				
			}
	}
