/*-------------------------------------------------------------------------------------------------
Animation Timings
-------------------------------------------------------------------------------------------------*/
	$animation-transition: 350ms; // used for various page transitions or UI states
	$animation-interaction: 250ms;


/*-------------------------------------------------------------------------------------------------
Breakpoints
-------------------------------------------------------------------------------------------------*/
	$breakpoint-large: 1200px;
	$breakpoint-desktop: 992px;
	$breakpoint-tablet: 768px;
	$breakpoint-phablet: 480px;
	$breakpoint-phone: 320px;

	$container-large: 1160px;
	$container-desktop: 960px;
	$container-tablet: 738px;


/*-------------------------------------------------------------------------------------------------
Colors
Theme colors found in /css/base/_themes.scss as CSS variables
They are not included here else they would be duplicated in component files which call this file
-------------------------------------------------------------------------------------------------*/
	
	// Standard Colors
		$color-white: #e2e2e2;
		$color-black: #16161d;
		$color-gray: #999;
		$color-gray-dark: #444;

	// User Notices
		$color-error: #a5282c;
		$color-error-alt: #000; // text color and/or border etc
		$color-success: #000;
		$color-success-alt: #000; // text color and/or border etc
		$color-warning: #000;
		$color-warning-alt: #000; // text color and/or border etc
		$color-info: #000;
		$color-info-alt: #000; // text color and/or border etc

/*-------------------------------------------------------------------------------------------------
Fonts 
-------------------------------------------------------------------------------------------------*/
	$font-family: 'Karla', "Lucida Sans Unicode", Verdana, sans-serif;

	$font-awesome: 'Font Awesome 5 Free';
	$font-awesome-brands: 'Font Awesome 5 Brands';
	
	$line-height: 1.5;
	$line-height-short: 1.3;
	$line-height-tall: 1.7;
