/*-------------------------------------------------------------------------------------------------
Achievements
-------------------------------------------------------------------------------------------------*/
	.comp-loading{
		position: relative;
		height: 0;
		transition: height 200ms linear;

		// Left/Right lines
		&:before,
		&:after{
			content: '';
			position: absolute;
			top:50%;
			margin-top: -3px;
			height: 3px;
			background: var(--theme-primary-dark);

			transform: scaleX(0);
			transition: 
				background-color $animation-transition ease-in,
				transform 200ms 200ms linear;
		}

		&:before{
			left:-30px;
			right: calc(50% + 150px);
			transform-origin: left;
		}
		&:after{
			right:-30px;
			left: calc(50% + 150px);
			transform-origin: right;
		}

		// Brackets
		.inner_container{
			position: relative;
			height: 158px;
			width: 300px;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;

			&:before,
			&:after{
				content: '';
				position: absolute;
				top:0;
				bottom:0;
				width: 3px;
				border: 3px solid var(--theme-primary-dark);
				transform: scaleY(0);
				transition:
					border-color $animation-transition ease-in,
					transform 200ms 400ms linear,
					width 100ms 600ms linear;
			}
			&:before{
				left:0;
				border-right: none;
				transform-origin: left center;
			}
			&:after{
				right:0;
				border-left: none;
				transform-origin: right center;
			}

			span,
			p{
				transition: opacity 250ms 600ms ease-in;
			}
		}

		// Spinner
		.spinner{
			display: block;
			width: 70px;
			margin: 0 auto;
			opacity: 0;
			transition: opacity $animation-transition ease-in;

			// Edge adjustments cause my animation is too much for it to handle
			.isEdge &{
				width:50px;
				margin-bottom: 10px;

				svg{
					fill: var(--theme-primary);
				}
			}

			// SVG
			// Note that the animations have been moved into 'active state' classes to prevent processing power when the animations are not visible. Might have been a Safari thing but just in case this is better this way.
			svg{
				stroke: var(--theme-primary-dark);

				transition: 
					stroke $animation-transition ease-in,
					fill $animation-transition ease-in;

				.inner-ring{
					// negative numbers account for stroke escaping normal bounding box
					clip-path: polygon(50% 52%, 50% 102%, 102% 102%, 102% 50%); 
					-webkit-clip-path: polygon(50% 52%, 50% 102%, 102% 102%, 102% 50%); 
					transform-origin: center;
				}

				.middle-ring{stroke-width: 1.5;}

				.outer-ring{
					// negative numbers account for stroke escaping normal bounding box
					clip-path: polygon(-2% -2%, 50% -2%, 50% 52%, -2% 52%); 
					-webkit-clip-path: polygon(-2% -2%, 50% -2%, 50% 52%, -2% 52%); 
					transform-origin: center;
				}

				.dot{
					stroke: none;
					fill: var(--theme-primary);
				}
			}
		}

		// Loading text
		p{opacity: 0;}

		/* Loading ------------------------------------------------------------------------------*/
			&.loading{
				height: 158px;

				// Horizontal lines
				&:before,
				&:after{
					transform: scaleX(1);
				}

				.inner_container{
					// Brackets
					&:before,
					&:after{
						transform: scaleY(1);
						width: 20px;
					}

					.spinner{
						opacity: 1;

						.inner-ring{
							animation: animateRotate360 700ms infinite reverse linear;
						}

						.middle-ring{
							animation: animateAJAXMiddleRing 1600ms alternate infinite linear;
						}

						.outer-ring{
							animation: animateRotate360 800ms infinite linear;
						}

						.dot{
							animation: animateFade 1600ms alternate-reverse infinite linear;
						}
					}
					p{
						animation: animateFade 1600ms alternate ease-in infinite;
					}
				}
			}

		/* Loaded -------------------------------------------------------------------------------*/
			&.loaded{
				height: 0;
				transition-delay: 650ms;

				// Horizontal lines
				&:before,
				&:after{
					animation: animateScaleX 200ms 450ms linear reverse forwards;
				}

				.inner_container{
					// Brackets
					&:before,
					&:after{
						animation:
							animateLoadingBracketsWidth 100ms 150ms linear reverse forwards,
							animateScaleY 200ms 250ms linear reverse forwards;
					}

					.spinner{
						animation: animateFade 250ms ease-in reverse forwards;

						.inner-ring{
							animation: animateRotate360 700ms infinite reverse linear;
						}

						.middle-ring{
							animation: animateAJAXMiddleRing 1600ms alternate infinite linear;
						}

						.outer-ring{
							animation: animateRotate360 800ms infinite linear;
						}

						.dot{
							animation: animateFade 1600ms alternate-reverse infinite linear;
						}
					}
					p{
						animation: animateFade 250ms ease-in reverse forwards;
					}
				}
			}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Tablet and down
			@include media-query(tablet-and-down){
				&:before{left:-15px;}
				&:after{right:-15px;}
			}

			// Phablet and down
			@include media-query(phablet-and-down){
				.inner_container{width: 230px;}
				&:before{
					right: calc(50% + 115px);
				}
				&:after{
					left: calc(50% + 115px);
				}
			}

			// Phone
			@include media-query(phone){
				&:before{left:-10px;}
				&:after{right:-10px;}
			}
	}

	/* Animations -------------------------------------------------------------------------------*/
		@keyframes animateAJAXMiddleRing{
			0%{
				stroke: var(--theme-primary-dark);
			}
			100%{
				stroke: var(--theme-primary);
			}
		}
