/*-------------------------------------------------------------------------------------------------
Forms
-------------------------------------------------------------------------------------------------*/
	.grecaptcha-badge{visibility: hidden;}
	.recaptcha{opacity: .5;}
	form{
		position: relative;
		margin-top: 30px;
		transition: opacity $animation-interaction ease-in;

		fieldset{
			border:none;
			> ul{
				margin: 0;
				list-style: none;

				> li{
					& + li{margin-top: 20px;}
					&.footer{
						display: flex;

						> div{
							width:75%;

							&:last-child{
								width: 25%;
							}
						}
					}

					// Errors
					.error{
						opacity: 0;
						transform: scale(0);
						display: none;
					}

					&.hasError{
						input[type="text"],
						input[type="email"],
						textarea,
						.astral-select > .toggle{
							border-color: $color-error;
							background: rgba($color-error, .2);

							&:hover,
							&:focus{
								border-color: $color-error;
								background: rgba($color-error, .5);
							}
						}
						.astral-select > .toggle{
							&:before,
							&:hover:before,
							&:focus:before{background: $color-error;}
						}

						.error{
							height: 18px;
							margin-left:10px;
							opacity: 1;
							transform: scale(1);
							transition: opacity $animation-interaction ease-in;
							display: inline;
						}
						textarea + .error{
							display: block;
							margin:5px 0 0;
						}
					}
				}
			}
		}

		label{
			display: block;
			max-width: 400px;
			font-size: 20px;
			font-weight: bold;
		}

		.btn{
			font-size: 18px;
			width:100%;
			margin-top: -1px;

			&[disabled],
			&[disabled]:hover,
			&[disabled]:focus{
				color: #444;
				background: rgba($color-black, .2);
				cursor: default;
			}
		}

		// Fade out and block interactions with form
		&.isProcessing{
			opacity: .5;
			&:after{
				content: '';
				position: absolute;
				top:0;left:0;right:0;bottom:0;
			}
		}

		// Fade Completely out
		&.isCompleted{
			opacity: 0;
		}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Tablet
			@include media-query(tablet-and-down){
				fieldset{
					> ul{
						> li{
							&.footer{
								flex-direction: column;
								> div{
									width: 100% !important;
									& + div{margin-top:22px;}
								}
							}

							&.hasError{
								.error{
									margin: 5px 0 0;
									display: block;
								}
							}
						}
					}
				}
			}

			// Phone
			@include media-query(phone){
				label,
				input[type="text"],
				input[type="email"]{max-width: auto;}
			}
	}

	/* Form Fields ------------------------------------------------------------------------------*/
		input[type="text"],
		input[type="email"],
		textarea{
			font-size: 18px;
			color: $color-white;
			padding: 5px 10px;
			width: 100%;
			max-width: 400px;
			border-radius: 0;
			-webkit-appearance: none;
			border: 2px solid var(--theme-primary-dark);
			background: rgba($color-black,.2);

			transition: 
				border-color $animation-interaction ease-in,
				background-color $animation-interaction ease-in;

			&:hover,
			&:focus{
				outline: none;
				border-color: var(--theme-primary);
				background: rgba($color-black,.5);
			}
		}
		textarea{
			max-width: inherit;
			height: 220px;
		}

	/* Astral - Custom Checkbox/Radio Buttons ---------------------------------------------------*/
		.astral-checkbox,
		.astral-radio{

			// Default Display
			& + label{
				position:relative;
				padding:4px 5px 0 45px;
				font-size: 18px;
				font-weight: normal;

				/* Responsive Updates -----------------------------------------------------------*/

					// Phone
					@include media-query(phone){
						padding-top: 6px;
						font-size: 15px;
					}

				// Box
				&:before{
					content: '';
					position: absolute;
					display: block;
					width:34px;
					height: 34px;
					top:0;left:0;
					border: 2px solid var(--theme-primary-dark);
					background: rgba($color-black,.2);

					transition: 
						border-color $animation-interaction ease-in,
						background-color $animation-interaction ease-in;
				}
			}

			// Focused
			&:focus + label{
				&:before{
					background: rgba($color-black,.5);
					border-color: var(--theme-primary);
				}
			}

			// Checked
			&:checked + label{

				// Box
				&:before{
					background: rgba($color-black,.5);
					border-color: var(--theme-primary);
				}

				// Checkmark
				&:after{
					content: '\f00c';
					font-family: $font-awesome;
					font-weight: 900;
					color: $color-white;
					font-size: 18px;
					position: absolute;
					top:4px;
					left:9px;
				}

			}

			// Disabled + Checked
			&.disabled:checked{
				& + label{
					cursor: default;
				}
			}


		}
		.astral-radio + label{padding-left:25px;}

	/* Checkboxes in Navigation --------------------------------------------------------------*/
		nav{
			.astral-checkbox,
			.astral-radio{

				// Default
				& + label{
					padding:3px 5px 0 32px;

					&:before{
						width:20px;
						height: 20px;
						background: $color-gray;
						border: none;
					}
				}

				// Checked
				&:checked + label{
					&:before{
						background: $color-black;
					}
					&:after{
						display: none;
					}
				}

			}
		}
