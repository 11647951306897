/*-------------------------------------------------------------------------------------------------
Themeable Colors - CSS Variables
Could use :root but need to override later, :root>html in specificity so this ignores !important
Unfortuantely, EDGE (damn you Microsoft!!! doesn't support #rrggbbaa so additional variables are needed)
-------------------------------------------------------------------------------------------------*/
	html{
		--theme-primary: #0091ab;
		--theme-primary-rgb: 0, 145, 171;
		--theme-primary-txt: #{$color-white};
		--theme-primary-dark: #006079;
		--theme-primary-dark-rgb: 0, 96, 121;
		--theme-secondary: #453488; // #332266 (previous purple, worked better in photoshop for some reason)
		--theme-bg-flash: url('../media/images/bg-flash-teal.png');

		--theme-prev-primary-rgb: 226, 226, 226;
		--theme-prev-primary-dark-rgb: 136, 136, 136;

		&.theme-red{
			--theme-primary: #ce1e29;
			--theme-primary-rgb: 206, 30, 41;
			--theme-primary-dark: #aa0b15;
			--theme-primary-dark-rgb: 170, 11, 21;
			--theme-bg-flash: url('../media/images/bg-flash-red.png');

			--theme-prev-primary-rgb: 0, 145, 171;
			--theme-prev-primary-dark-rgb: 0, 96, 121;
		}
		&.theme-purple{
			// prince purple = 563965 - 86 57 101, too dark :(;
			--theme-primary: #b755e8;
			--theme-primary-rgb: 183, 85, 232;
			--theme-primary-dark: #941dcf;
			--theme-primary-dark-rgb: 148, 29, 207;
			--theme-bg-flash: url('../media/images/bg-flash-purple.png');

			--theme-prev-primary-rgb: 206, 30, 41;
			--theme-prev-primary-dark-rgb: 170, 11, 21;
		}
		&.theme-pink{
			--theme-primary: #fd7198;
			--theme-primary-rgb: 253, 113, 152;
			--theme-primary-dark: #ad4d67;
			--theme-primary-dark-rgb: 173, 77, 103;
			--theme-bg-flash: url('../media/images/bg-flash-pink.png');

			--theme-prev-primary-rgb: 148, 29, 207;
			--theme-prev-primary-dark-rgb: 93, 7, 138;
		}
		&.theme-blue{
			--theme-primary: #33b0ea;
			--theme-primary-rgb: 51, 176, 234;
			--theme-primary-dark: #016d9c;
			--theme-primary-dark-rgb: 1, 109, 156;
			--theme-bg-flash: url('../media/images/bg-flash-blue.png');

			--theme-prev-primary-rgb: 253, 113, 152;
			--theme-prev-primary-dark-rgb: 173, 77, 103;
		}
		&.theme-yellow{
			--theme-primary: #e5d45e;
			--theme-primary-rgb: 229, 212, 94;
			--theme-primary-txt: #{$color-black};
			--theme-primary-dark: #d0b300;
			--theme-primary-dark-rgb: 208, 179, 0;
			--theme-bg-flash: url('../media/images/bg-flash-yellow.png');

			--theme-prev-primary-rgb: 51, 176, 234;
			--theme-prev-primary-dark-rgb: 1, 109, 156;
		}
		&.theme-orange{
			--theme-primary: #d95b1e;
			--theme-primary-rgb: 217, 91, 30;
			--theme-primary-dark: #a64617;
			--theme-primary-dark-rgb: 166, 70, 23;
			--theme-bg-flash: url('../media/images/bg-flash-orange.png');

			--theme-prev-primary-rgb: 229, 212, 94;
			--theme-prev-primary-dark-rgb: 236, 202, 1;
		}
		&.theme-green{
			--theme-primary: #22b10f;
			--theme-primary-rgb: 34, 177, 15;
			--theme-primary-dark: #0f8100;
			--theme-primary-dark-rgb: 15, 129, 0;
			--theme-bg-flash: url('../media/images/bg-flash-green.png');

			--theme-prev-primary-rgb: 217, 91, 30;
			--theme-prev-primary-dark-rgb: 166, 70, 23;
		}
		&.theme-white{
			--theme-primary: #{$color-white};
			--theme-primary-rgb: 226, 226, 226;
			--theme-primary-txt: #{$color-black};
			--theme-primary-dark: #888;
			--theme-primary-dark-rgb: 136, 136, 136;
			--theme-bg-flash: url('../media/images/bg-flash-white.png');

			--theme-prev-primary-rgb: 34, 177, 15;
			--theme-prev-primary-dark-rgb: 15, 129, 0;
		}
	}
