/*-------------------------------------------------------------------------------------------------
Print Styles
-------------------------------------------------------------------------------------------------*/
	@media print {

		/* Reset ------------------------------------------------------------------------------------*/
			*{text-shadow: none !important; filter:none !important; -ms-filter: none !important; box-shadow: none !important;-webkit-print-color-adjust: exact;}
			a,a:visited{text-decoration: underline;}
			/*a[href]:after{content: " (" attr(href) ")";}
			abbr[title]:after{content: " (" attr(title) ")";}
			.ir a:after, a[href^="javascript:"]:after, a[href^="#"]:after { content: "";}  Don't show links for images, or javascript/internal links */
			pre{border: 1px solid #999; page-break-inside: avoid; }
			thead{display: table-header-group;}
			tr, img{page-break-inside: avoid;}
			img{max-width: 100% !important;}
			@page{margin:1.5cm 1cm;}
			p, h2, h3{orphans: 3; widows: 3;}
			h2, h3{page-break-after: avoid;}

			.visible-print{display: block !important;}
			.hidden-print{display: none !important;}

		
		/* Project Specific -------------------------------------------------------------------------*/
			body{background: #fff;}
			#template .container{width:100% !important;}
			#page-resume{
				width:100% !important;

				h1{color: var(--theme-primary) !important;}
				h2{
					font-size: 24px;
					color: var(--theme-primary) !important;
				}
				h3{
					font-size: 20px;
					.float-right{
						float: none;
						&:before{
							content: ' - ';
						}
					}
				}
				h3, h4, p, ul, a{
					color: $color-black !important;
				}
				> div > div{margin-left: 15px;}
				.employment > div{page-break-inside: avoid;}
				.skills > div ul:not(:first-child){margin-left: 45px;}
			}

			.theme-white{
				body{
					background: $color-black;

					#page-resume{
						h3, h4, p, ul, a{
							color: var(--theme-primary-dark) !important;
						}
					}
				}
			}
	}
