/*-------------------------------------------------------------------------------------------------
Gallery
-------------------------------------------------------------------------------------------------*/
	.comp-gallery{
		position:relative;
		width: 586px;
		height: 698px;
		margin:0 auto 38px;
		border: 3px solid var(--theme-primary-dark);
		transition: border-color $animation-transition ease-in;

		// Wrapper
		.wrapper{
			position: relative;
			opacity: 0;
		}

		// Horizontal lines
		&:before,
		&:after{
			content: '';
			position: absolute;
			height: 3px;
			width: 100px;
			left:-100px;
			top:142px;
			background: var(--theme-primary-dark);
			transition: background-color $animation-transition ease-in;

			// Animated Properties
			transform: scaleX(0);
		}
		&:after{top:auto;bottom:142px;left:auto;right:-101px;}

		// Buttons
		.btn-prev,
		.btn-next{
			position: absolute;
			top:50%;
			margin-top:-62px;
			padding: 0;
			width:50px;
			height: 124px;
			display: block;
			text-align: center;
			font-size: 30px;
			line-height: 124px;
			border: 3px solid var(--theme-primary-dark);

			// Animated Properties
			opacity: 0;

			> span{position:relative;top:-3px;}
		}
		.btn-prev{left:-53px;border-right:none;}
		.btn-next{right:-53px;border-left:none;}

		// Cycle
		.cycle{
			margin: 0;
			list-style: none;
			overflow: hidden;
			width: 580px;
			height: 692px;
			z-index: 100;

			> li{
				display: block;
				width: 580px;
				height: 692px;
				float:left;

				& + li{margin-top:0;}

				a{display: block;}
				img{width:100%;}
			}
		}

		// Caption(slide counter)
		.caption{
			position: absolute;
			top: -30px;
			right:-3px;
			color: $color-white;
			font-size: 14px;
			width: 60px;
			height: 30px;
			line-height: 25px;
			text-align: center;
			padding: 0 5px;
			border: 3px solid var(--theme-primary-dark);
			background: rgba($color-black, .8);
			z-index: 100;
			transition: border-color $animation-transition ease-in;

			span{
				display: none;

				&.active{display:inline;}
			}
		}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Desktop
			@include media-query(desktop){
				width: 460px;
				height: 548px;
				
				// Cycle
				.cycle{
					width: 454px;
					height: 542px;

					> li{
						width: 454px;
						height: 542px;
					}
				}
			}

			// Tablet and down
			@include media-query(tablet-and-down){
				width: 422px;
				height: 502px;
				margin-bottom: 85px;
				
				// Buttons
				.btn-prev,
				.btn-next{
					margin: 0;
					top: auto;
					bottom: -50px;
					height: 50px;
					line-height: 54px;
					width:50%;
					z-index: 100;
					//background: var(--theme-primary);
				}
				.btn-prev{
					left:-3px;
					border-right: 3px solid var(--theme-primary-dark);
				}
				.btn-next{
					right:-3px;
					border-left: 3px solid var(--theme-primary-dark);
				}

				// Cycle
				.cycle{
					width: 416px;
					height: 496px;

					> li{
						width: 416px;
						height: 496px;
					}
				}
			}

			// Phablet and Down
			@include media-query(phablet-and-down){
				width: auto;
				height: auto;
				max-width: 580px;

				// Cycle
				.cycle{
					width: auto;
					height: auto;

					> li{
						width: auto;
						height: auto;
					}
				}
			}
	}
