/*-------------------------------------------------------------------------------------------------
Base Component Styles
/*-----------------------------------------------------------------------------------------------*/
	* + div[class^="comp-"],
	div[class^="comp-"] + *{margin-top: 30px;}

	@import 'achievements';
	@import 'gallery';
	@import 'loading';
	@import 'multicols';
	@import 'pager';
	@import 'quotes';
	@import 'text';
	@import 'video';
