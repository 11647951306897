/*-------------------------------------------------------------------------------------------------
Animations (common)
------------------------------------------------------------------------------------------------*/

	/* Fades -----------------------------------------------------------------------------------*/
	
		// Fade
		@keyframes animateFade{
			0%{opacity: 0;}
			100%{opacity: 1;}
		}

	/* Rotations --------------------------------------------------------------------------------*/

		// 360
		@keyframes animateRotate360{
			100%{transform: rotate(360deg);}
		}

		// 360 - Y only
		@keyframes animateRotateY360{
			100%{transform: rotateY(360deg);}
		}

	/* Scaling ----------------------------------------------------------------------------------*/

		// Scale X
		@keyframes animateScaleX{
			0%{transform: scaleX(0);}
			100%{transform: scaleX(1);}
		}

		// Scale Y
		@keyframes animateScaleY{
			0%{transform: scaleY(0);}
			100%{transform: scaleY(1);}
		}
