/*-------------------------------------------------------------------------------------------------
Text
-------------------------------------------------------------------------------------------------*/
	.comp-text{
		background: rgba($color-black,.5);
		padding: 13px 20px 14px;
		width: 90%;
		position: relative;
		color: $color-white;

		h3 + &{margin-top:5px;}

		&:before,
		&.tall:after{
			content: '';
			position: absolute;
			height: 3px;
			width: 33px;
			left:-33px;
			top:50%;
			margin-top:-1px;
			background: var(--theme-primary-dark);
			transition: background-color $animation-transition ease-in;
		}

		&.tall{
			&:before{top:47px;margin:0;}
			&:after{top:auto;bottom:47px;margin:0;}
		}

		&.border{
			border: 3px solid var(--theme-primary-dark);
			transition: border-color $animation-transition ease-in;
		}
		&.right{
			margin-left: calc(10% - 30px);

			&:before,
			&.tall:after{
				left: auto;
				right: -34px;
			}
		}

		/* Success & Errors ---------------------------------------------------------------------*/
			&.errors{
				border-color: $color-error;
				background: rgba($color-error, .5);
			}

			&.success,
			&.errors{
				display: none;

				&:before,
				&.tall:after{display: none;}
			}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Tablet
			@include media-query(tablet-and-down){
				&:before,
				&.tall:after{
					width: 18px;
					left: -18px;
				}
				&.right:before,
				&.right.tall:after{
					width: 18px;
					left: auto;
					right: -18px;
				}
			}

			// Phablet
			@include media-query(phablet){
				padding: 13px 15px 14px;
				ul{margin-left: 15px;}
			}

			// Phone
			@include media-query(phone){
				padding: 13px 10px 14px;
				ul{margin-left: 15px;}

				&:before,
				&.tall:after{
					width: 13px;
					left: -13px;
				}
				&.right:before,
				&.right.tall:after{
					width: 13px;
					left: auto;
					right: -13px;
				}
			}
	}
