/*-------------------------------------------------------------------------------------------------
Achievements
-------------------------------------------------------------------------------------------------*/
	.comp-achievements{
		ul{
			margin: 0;
			list-style: none;

			li{
				position: relative;
				padding: 8px 8px 8px 55px;
				opacity: 0;
				transform: translateX(-100%);
				background: rgba(var(--theme-primary-rgb), .4);
				transition: background-color $animation-transition ease-in;

				& + li{margin-top: 5px;}

				h6{margin:-3px 0 -2px;}
				p{font-size: 12px;}
				h6,p{
					color: var(--theme-primary-txt);
					transition: color $animation-transition ease-in;
				}

				// Icon
				.icon{
					position: absolute;
					left:8px;
					top:8px;
					margin:0;

					&:before{
						content: '';
						display: block;
						width:34px;
						height: 34px;
						background: rgba(22,22,29,.4);
					}
				}

				// Animate items in on a slight delay for a cascade effect
				&:nth-child(1){animation: animateAchievementIn 200ms ease-in forwards;}
				&:nth-child(2){animation: animateAchievementIn 200ms 150ms ease-in forwards;}
				&:nth-child(3){animation: animateAchievementIn 200ms 300ms ease-in forwards;}
				&:nth-child(4){animation: animateAchievementIn 200ms 450ms ease-in forwards;}
				&:nth-child(5){animation: animateAchievementIn 200ms 600ms ease-in forwards;}
			}
		}
		.theme-yellow & ul li{background: rgba(var(--theme-primary-rgb), .6);}

		/* Variants -----------------------------------------------------------------------------*/

			/* Reloaded -------------------------------------------------------------------------*/
				&.reloaded{
					button{
						border: none;
						background: none;
						color: var(--theme-primary-dark);
						transition: color $animation-transition ease-in;

						.fas{transform-origin: center;}
						&:focus,
						&:hover{color: var(--theme-primary);}

						&.triggered{
							.fas{animation: animateRotate360 $animation-interaction linear 1;}
						}
					}

					/* Responsive Updates -------------------------------------------------------*/

						// Tablet and down
						@include media-query(tablet-and-down){
							button{
								color: var(--theme-primary);
							}
						}

						// Phablet and down
						@include media-query(phablet-and-down){
							margin-left: auto;
							margin-right: auto;

							h5{text-align: center;}
							hr{
								background: linear-gradient(to right, 
									rgba(var(--theme-primary-rgb), 0) 0,
									rgba(var(--theme-primary-rgb), 1) 25%,
									rgba(var(--theme-primary-rgb), 1) 75%,
									rgba(var(--theme-primary-rgb), 0) 100%,
								);

								&:after{
									background: linear-gradient(to right, 
										rgba(var(--theme-prev-primary-rgb), 0) 0,
										rgba(var(--theme-prev-primary-rgb), 1) 25%,
										rgba(var(--theme-prev-primary-rgb), 1) 75%
										rgba(var(--theme-prev-primary-rgb), 0) 100%
									);
								}
							}

							ul{
								li{
									transform: none;
									animation-name: animateFade;
								}
							}
						}
				}
	}

	/* Animations -------------------------------------------------------------------------------*/
		@keyframes animateAchievementIn{
			100%{
				opacity: 1;
				transform: translateX(0);
			}
		}
