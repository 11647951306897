/*-------------------------------------------------------------------------------------------------
Video
-------------------------------------------------------------------------------------------------*/
	.comp-video{
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;
		margin: 0 auto 38px;
		background: #000;
		border: 3px solid rgba(var(--theme-primary-dark-rgb), 0);
		transition: 
			border-color $animation-transition ease-in,
			background 400ms 400ms ease-in;

		// Horizontal lines
		&:before,
		&:after{
			content: '';
			position: absolute;
			height: 3px;
			width: 30px;
			left:-33px;
			bottom:100px;
			background: var(--theme-primary-dark);
			transition: background-color $animation-transition ease-in;

			// Animated Properties
			transform: scaleX(0);
		}
		&:after{top:100px;bottom:auto;left:auto;right:-33px;}

		iframe,
		object,
		embed,
		video{
			opacity: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border:none;
		}
	}
