/*-------------------------------------------------------------------------------------------------
Buttons
-------------------------------------------------------------------------------------------------*/
	a.btn,
	.btn{
		font-size: 14px;
		display: inline-block;
		padding: 6px 25px;	
		text-transform: uppercase;
		position: relative;
		border: none;
		color: var(--theme-primary-txt);
		background: rgba(var(--theme-primary-rgb), .5);
		transition: 
			background-color $animation-interaction ease-out,
			color $animation-interaction ease-out,
			border-color $animation-transition ease-in,
			opacity $animation-transition ease-in
		;

		&:hover,
		&:focus{
			color: var(--theme-primary-txt);
			background: rgba(var(--theme-primary-rgb), 1);
		}
		&:active{
			color: var(--theme-primary-txt);
		}

		// 4-corners
		&:not(.hasNoFocusCorners):focus{
			outline: none;

			// Basic structure for corners
			&:before,
			&:after{
				content: '';
				position: absolute;
				right: -5px;
				top: -5px;
				left: -5px;
				bottom: -5px;
				border: 1px solid;
				animation: animateFade 150ms linear 1 alternate;
			}

			// Top/Bottom Lines
			&:before{
				border-image:
					linear-gradient(to right,
						rgba(226, 226, 226, 1) 0,
						rgba(226, 226, 226, 1) 6px,
						rgba(226, 226, 226, 0) 6px,
						rgba(226, 226, 226, 0) calc(100% - 6px),
						rgba(226, 226, 226, 1) calc(100% - 6px),
						rgba(226, 226, 226, 1) 100%
					) 1 1 100%
				;
			}

			// Left/Right Lines
			&:after{
				border-image: 
					linear-gradient(to bottom,
						rgba(226, 226, 226, 1) 0,
						rgba(226, 226, 226, 1) 6px,
						rgba(226, 226, 226, 0) 6px,
						rgba(226, 226, 226, 0) calc(100% - 6px),
						rgba(226, 226, 226, 1) calc(100% - 6px),
						rgba(226, 226, 226, 1) 100%
					) 1 100%
				;
			}
		}

	/* Variants -----------------------------------------------------------------------------*/

		// Icon
		&.btn-icon{
			font-size: 19px;
			color: $color-white;
			text-align: center;
			width: 34px;
			height: 34px;
			padding: 0;
			line-height: 34px;
			background: rgba($color-black, .5);

			&:hover,
			&:focus{
				color: var(--theme-primary);
				background: rgba($color-black, 1);
			}
			&:active{
				color: $color-white;
			}

			// Font awesome icon positions
				span[class*="fa-"]{position: relative;top:1px;}
		}
}
