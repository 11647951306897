/*-------------------------------------------------------------------------------------------------
Icons
/*-----------------------------------------------------------------------------------------------*/
	.icon{
		display: inline-block;
		width: 34px;
		height: 34px;
		overflow: hidden;
		background: url('../media/images/sprite.png') no-repeat;
		text-indent:-999px;
	}

	/* Achivements ------------------------------------------------------------------------------*/

		/* Row 1 */
			.icon.chicken{background-position:0 0;}
			.icon.dragonball{background-position:-35px 0;}
			.icon.wr{background-position:-70px 0;}
			.icon.hearts{background-position:-105px 0;}
			.icon.clock{background-position:-140px 0;}
			.icon.planet{background-position:-175px 0;}
			.icon.controller{background-position:-210px 0;}
			.icon.d20{background-position:-245px 0;}
			.icon.baseball{background-position:-280px 0;}
			.icon.basketball{background-position:-315px 0;}
			.icon.css{background-position:-350px 0;}
			.icon.resistance{background-position:-385px 0;}
			.icon.epi{background-position:-420px 0;}

		/* Row 2 */
			.icon.gradcap{background-position:0 -35px;}
			.icon.swords{background-position:-35px -35px;}
			.icon.sixtySymbols{background-position:-70px -35px;}
			.icon.kfc{background-position:-105px -35px;}
			.icon.marshal{background-position:-140px -35px;}
			.icon.battlemaster{background-position:-175px -35px;}
			.icon.tree{background-position:-210px -35px;}
			.icon.bike{background-position:-245px -35px;}
			.icon.d2{background-position:-280px -35px;}
			.icon.guitar{background-position:-315px -35px;}
			.icon.starfleet{background-position:-350px -35px;}
			.icon.jedi{background-position:-385px -35px;}
			.icon.disarray{background-position:-420px -35px;}

		/* Row 3 */
			.icon.ff7{background-position:0 -70px;}
			.icon.book{background-position:-35px -70px;}
			.icon.scouter{background-position:-70px -70px;}
			.icon.award{background-position:-105px -70px;}
			.icon.cowboy{background-position:-140px -70px;}
			.icon.paintball{background-position:-175px -70px;}
			.icon.anime{background-position:-210px -70px;}
			.icon.car{background-position:-245px -70px;}
			.icon.ring{background-position:-280px -70px;}
			.icon.zzz{background-position:-315px -70px;}
			.icon.star{background-position:-350px -70px;}
			.icon.easy{background-position:-385px -70px;}
			.icon.rogue{background-position:-420px -70px;}

		/* Row 4 */
			.icon.apache{background-position:0 -105px;}
			.icon.wow{background-position:-35px -105px;}
			.icon.infinity{background-position:-70px -105px;}
			.icon.terminal{background-position:-105px -105px;}
			.icon.android{background-position:-140px -105px;}
			.icon.google{background-position:-175px -105px;}
			.icon.chrome{background-position:-210px -105px;}
			.icon.firefox{background-position:-245px -105px;}
			.icon.ie{background-position:-280px -105px;}
			.icon.safari{background-position:-315px -105px;}
			.icon.buzz{background-position:-350px -105px;}
			.icon.smacss{background-position:-385px -105px;}

		/* Row 5 */
			.icon.list{background-position:0 -140px;}
			.icon.curses{background-position:-35px -140px;}
			.icon.missingSemicolons{background-position:-70px -140px;}
			.icon.drink{background-position:-105px -140px;}
			.icon.apple{background-position:-140px -140px;}
			.icon.windows{background-position:-175px -140px;}
			.icon.langs{background-position:-210px -140px;}
			.icon.fn{background-position:-245px -140px;}
			.icon.exclamation{background-position:-280px -140px;}
			.icon.bugs{background-position:-315px -140px;}
			.icon.layerslider{background-position:-350px -140px;}
			.icon.ux{background-position:-385px -140px;}

		/* Row 6 */
			.icon.pages{background-position:0 -175px;}
			.icon.key{background-position:-35px -175px;}
			.icon.recycle{background-position:-70px -175px;}
			.icon.layers{background-position:-105px -175px;}
			.icon.filmstrip{background-position:-140px -175px;}
			.icon.soundSliders{background-position:-175px -175px;}
			.icon.flame{background-position:-210px -175px;}
			.icon.script{background-position:-245px -175px;}
			.icon.dice{background-position:-280px -175px;}
			.icon.clocks{background-position:-315px -175px;}
			.icon.interaction{background-position:-350px -175px;}
			.icon.sitecore{background-position:-385px -175px;}

		/* Row 7 */
			.icon.questionMarks{background-position:0 -210px;}
			.icon.lightsaber{background-position:-35px -210px;}
			.icon.disapproval{background-position:-70px -210px;}
			.icon.cogwheels{background-position:-105px -210px;}
			.icon.webkit{background-position:-140px -210px;}
			.icon.comments{background-position:-175px -210px;}
			.icon.mobile{background-position:-210px -210px;}
			.icon.db{background-position:-245px -210px;}
			.icon.hammer{background-position:-280px -210px;}
			.icon.feather{background-position:-315px -210px;}
			.icon.number1{background-position:-350px -210px;}
			.icon.prototype{background-position:-385px -210px;}

		/* Row 8 */
			.icon.like{background-position:0 -245px;}
			.icon.protoss{background-position:-35px -245px;}
			.icon.dustpan{background-position:-70px -245px;}
			.icon.code{background-position:-105px -245px;}
			.icon.playcall{background-position:-140px -245px;}
			.icon.shuffle{background-position:-175px -245px;}
			.icon.ae{background-position:-210px -245px;}
			.icon.save{background-position:-245px -245px;}
			.icon.repeat{background-position:-280px -245px;}
			.icon.dualEditor{background-position:-315px -245px;}
			.icon.whistle{background-position:-350px -245px;}
			.icon.reticle{background-position:-385px -245px;}

		/* Row 9 */
			.icon.kotaku{background-position:0 -280px;}
			.icon.ai{background-position:-35px -280px;}
			.icon.refresh{background-position:-70px -280px;}
			.icon.glasses{background-position:-105px -280px;}
			.icon.objectception{background-position:-140px -280px;}
			.icon.arrayception{background-position:-175px -280px;}
			.icon.lightning{background-position:-210px -280px;}
			.icon.opera{background-position:-245px -280px;}
			.icon.overwatch{background-position:-280px -280px;}
			.icon.bloodborne{background-position:-315px -280px;}
			.icon.eraser{background-position:-350px -280px;}
			.icon.scuba{background-position:-385px -280px;}

		/* Row 10 */
			.icon.darksign{background-position:0 -315px;}
			.icon.rook{background-position:-35px -315px;}
			.icon.dnd{background-position:-70px -315px;}
			.icon.batman{background-position:-105px -315px;}
			.icon.worm{background-position:-140px -315px;}
			.icon.mclaren{background-position:-175px -315px;}
			.icon.tmnt{background-position:-210px -315px;}
			.icon.smashbros{background-position:-245px -315px;}
			.icon.ff{background-position:-280px -315px;}
			.icon.packers{background-position:-315px -315px;}
			.icon.knight{background-position:-350px -315px;}
			.icon.film{background-position:-385px -315px;}

		/* Row 11 */
			.icon.trophy{background-position:0 -350px;}
			.icon.n4s{background-position:-35px -350px;}
			.icon.starcraft{background-position:-70px -350px;}
			.icon.mightyDucks{background-position:-105px -350px;}
			.icon.zim{background-position:-140px -350px;}
			.icon.bulls{background-position:-175px -350px;}
			.icon.powerRangers{background-position:-210px -350px;}
			.icon.codeGeass{background-position:-245px -350px;}
			.icon.celestialBeing{background-position:-280px -350px;}
			.icon.jimmyNeutron{background-position:-315px -350px;}
			.icon.gun{background-position:-350px -350px;}
			.icon.eye{background-position:-385px -350px;}

		/* Row 12 */
			.icon.bleach{background-position:0 -385px;}
			.icon.tracer{background-position:-35px -385px;}
			.icon.reinhardt{background-position:-70px -385px;}
			.icon.lucio{background-position:-105px -385px;}
			.icon.lol{background-position:-140px -385px;}
			.icon.knife{background-position:-175px -385px;}
			.icon.zelda{background-position:-210px -385px;}
			.icon.who{background-position:-245px -385px;}
			.icon.satalite{background-position:-280px -385px;}
			.icon.rdm{background-position:-315px -385px;}
			.icon.jokers{background-position:-350px -385px;}
			.icon.identity{background-position:-385px -385px;}

		/* Row 13 */
			.icon.criticalRole{background-position:0 -420px;}
			.icon.sekiro{background-position:-35px -420px;}
			.icon.olmec{background-position:-70px -420px;}
			.icon.stackOverflow{background-position:-105px -420px;}
			.icon.nier{background-position:-140px -420px;}
			.icon.gow{background-position:-175px -420px;}
			.icon.psn{background-position:-210px -420px;}
			.icon.hollowKnight{background-position:-245px -420px;}
			.icon.reddit{background-position:-280px -420px;}
			.icon.rules{background-position:-315px -420px;}
			.icon.minnesota{background-position:-350px -420px;}
			.icon.aware{background-position:-385px -420px;}
