/*-------------------------------------------------------------------------------------------------
Custom Universal Classes / Web Defined Fixes / Sitecore RTE Classes
-------------------------------------------------------------------------------------------------*/
	.float-left{float:left;}
	.float-right{float:right;}
	.float-clear{clear:both;}

	.text-align-left{text-align:left;}
	.text-align-right{text-align:right;}
	.text-align-center{text-align:center;}

	.text-lowercase{text-transform: lowercase;}
	.text-uppercase{text-transform: uppercase;}
	.text-capitalize{text-transform: capitalize;}

	/* Images -----------------------------------------------------------------------------------*/
		.img-align-left{float:left;margin:0 10px 10px 0;}
		.img-align-right{float:right;margin:0 0 10px 10px;}
		.img-align-center{display:block;margin: 10px auto;}

		.img-100{width:100%;}.img-80{width:80%;}.img-75{width:75%;}
		.img-66{width:66%;}.img-60{width:60%;}.img-50{width:50%;}
		.img-40{width:40%;}.img-33{width:33%;}.img-25{width:25%;}
		.img-20{width:20%;}.img-static{width:auto;max-width:100%;} /* needs testing */

	/* Cloaking ---------------------------------------------------------------------------------*/

		// Hide From Browsers + screenreaders
			.hidden{display: none !important;visibility: hidden;} 

		// Hide only visually, not screenreaders
			.sr-only{
				border: 0;
				clip: rect(0 0 0 0);
				height: 1px;
				margin: -1px;
				overflow: hidden;
				padding: 0;
				position: absolute;
				width: 1px;

				// Allows sr-only to be focusable via keyboard
				&.focusable:active,
				&.focusable:focus{
					clip: auto;
					height: auto;
					margin: 0;
					overflow: visible;
					position: static;
					width: auto;
				}
			}

		// Hide visually but maintain layout
			.invisible{visibility: hidden;} 
			.visible-print{display: none !important;} /* Use on non styled containers to prevent display clashes */

	/* Clearfix ---------------------------------------------------------------------------------*/
		.clearfix:after{
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		} 
		.clearfix{display: block;}
		* html .clearfix{height: 1%;}

	/* Responsive -------------------------------------------------------------------------------*/
		@include media-query(large){
			.hidden-large{display: none;}
			.visible-large{display: inherit;}
		}
		@include media-query(desktop){
			.hidden-desktop{display: none;}
			.visible-desktop{display: inherit;}
		}
		@include media-query(tablet){
			.hidden-tablet{display: none;}
			.visible-tablet{display: inherit;}
		}
		@include media-query(phablet){
			.hidden-phablet{display: none;}
			.visible-phablet{display: inherit;}
		}
		@include media-query(phone){
			.hidden-phone{display: none;}
			.visible-phone{display: inherit;}
		}
