// Variables & Mixins
@import '../base/variables';
@import '../mixins/mixins';

/*-------------------------------------------------------------------------------------------------
Astral Tooltip
-------------------------------------------------------------------------------------------------*/

	.astral-tooltip{
		position:absolute;
		display:none;
		z-index:1000;

		&.visible{
			display: block;
		}
	}

	// Advanced Tooltip
	div.astral-tooltip{
		background: rgba($color-black, .8);
		color: $color-white;
		border: 2px solid var(--theme-primary-dark);
		padding: 10px;
		width: 300px;

		// overrides, unique implementation, important is to override JS injected values
		left:auto !important;
		right:0 !important;
		margin-left: 0 !important;
	}
