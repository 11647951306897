// Variables & Mixins
@import '../base/variables';
@import '../mixins/mixins';
	
/*-------------------------------------------------------------------------------------------------
Astral Expandables
-------------------------------------------------------------------------------------------------*/

	// Base Structure
	.astral-expandables{
		position: relative;
		margin:30px 0 0 !important;

		& + *{margin-top: 30px;}

		> .expandable{
			position:relative;
			z-index: 1;
			overflow: hidden;
			padding: 0 30px;
			height: 93px;
			margin-top: 30px;
			transition: 
				margin $animation-transition linear,
				opacity $animation-transition ease-in;

			&.open{height: auto;}

			&:first-child{margin-top:0;}

			// Hidden State
			&.isHidden{
				opacity: 0;
				height: 0;
				margin-bottom: -30px;
			}

			// Is being hid
			&.isHiding{
				margin-bottom: -30px; // needed for Edge/Safari; seems the order of opperations fire differently there leaving a brief window for it to start 'hiccupping' (i.e. animating back into position)
				animation: animateHideFilteredExpandable $animation-transition linear forwards;
			}

			// Is being Revealed
			&.isRevealing{
				margin-bottom: 0; // needed for Edge/Safari; seems the order of opperations fire differently there leaving a brief window for it to start 'hiccupping' (i.e. animating back into position)
				animation: animateRevealFilteredExpandable $animation-transition linear forwards;
			}
		}

		// 'Disable' Expandables
		&.isFocused{
			&:after{
				content: '';
				position: absolute;
				top:0;right:0;
				bottom:0;left:0;
				z-index: 1;
			}
			> .expandable:not(.open){
				opacity: .3;
				z-index: 0;
			}
		}
	}

	// Expandable
	.expandable{

		&:before,
		&:after{
			content: '';
			position: absolute;
			top:23px;
			left:0;
			height: 3px;
			width: 30px;
			background: var(--theme-primary-dark);
			transition: background-color $animation-transition ease-in;
		}
		&:after{left: auto;right:0;}

		/* Triggering Element(link)
			Normally I'd put these lines on the .trigger element but due to needing to hide excess skewed background they had to be placed here. 
		*/
		.trigger{
			display: flex;
			cursor: pointer;
			justify-content: space-between;
			position: relative;
			overflow: hidden;
			transition: opacity $animation-interaction ease-out;

			h2,.utility{
				font-size: 27px;
				position: relative;
				padding: 3px 15px 5px;
				font-weight: normal;
				transition: color $animation-transition ease-in;

				&:before{
					content: '';
					position: absolute;
					top:0;
					left:-48px;
					right:0;
					height: 48px;
					border: 2px solid var(--theme-primary-dark);
					border-right-width: 3px;
					border-left: none;
					transform: skew(-45deg);
					background: rgba(0,0,0,.2);
					transition: background-color $animation-transition ease-in, border-color $animation-transition ease-in;
					z-index: -1;
				}
				&:after{
					content: '';
					position: absolute;
					top:0;
					left: 0;
					bottom:0;
					width: 2px;
					background: var(--theme-primary-dark);
					transition: background-color $animation-transition ease-in;
				}
			}
			h2{
				width: 75%;
				padding-right: 30px;
			}
			.utility{
				max-width: 20%;
				text-align: right;
				padding-left: 30px;
				transition: width 200ms linear;

				&:before{
					left:0;
					right:-48px;
					border-left: 3px solid var(--theme-primary-dark);
					border-right: 0;
				}
				&:after{
					left: auto;
					right: 0;
				}

				.fas{
					font-size: 20px;

					&:before{
						display: block;
						transform-origin: center;
						transition: transform 200ms ease-in;
					}
				}
			}

			&:hover,
			&:focus{
				outline: none;
				h2,.utility{
					&:before{
						background: rgba(0,0,0,.5);
					}
				}
			}
		}

		// Content
		> .content{
			position:absolute;
			float:left;
			z-index:-1;
			visibility:hidden;
			padding:40px 30px 30px;
			width: 100%;
			overflow: hidden; // may need to remove this but would prevent horizontal-lines from escaping outside container
			border-left: 3px solid var(--theme-primary-dark);
			border-right: 3px solid var(--theme-primary-dark);
			transition: 
				border-color $animation-transition ease-in,
				opacity $animation-interaction ease-out;

			// Half-tall line (for when a project is loading)
			&:after{
				content: '';
				position: absolute;
				width: 3px;
				right:0;
				top:0;
				bottom: calc(50% - 3px);
				background: var(--theme-primary-dark);

				// animation properties
				transform-origin: top;
				transform: scaleY(0);

				transition: 
					background-color $animation-transition ease-in,
					transform 200ms linear;
			}

			// Slider
			// hide visually but we need it to still generate height for expandable to open smoothly
			.comp-gallery{visibility: hidden;}
			.comp-video{background: rgba(0,0,0,0);}

 			// Mobile Highlights button
 			.launch-modal_highlights{
 				display: none;
				position: absolute;
				top:-30px;
				left: -3px;
				padding: 4px 25px 3px;
				border: 3px solid var(--theme-primary-dark);
				background: var(--theme-primary);
			}

			// Loading
			.comp-loading{height: 158px;}

			// Info
			.info{
				position: relative;
				display: flex;
				flex-direction: column;

				// Details
				.details{
					max-width: calc(100% - 33px);

					// Animation content in
					opacity: 0;
					transform: translateX(-100%);

					// Reset width of comp-text since the above max-width dictates the overall size now
					.comp-text{width:100%;}
				}

				// Clipart
				.clipart{
					position: absolute;
					left: 30px;
					width: 200px;
					margin:0;
					opacity: 0;

					svg{
						stroke: var(--theme-primary-dark);
						transition: stroke $animation-transition ease-in;

						.connection{
							transform-origin: top;
							transform: scaleY(0);
						}
					}
				}

				// Achievements
				.comp-achievements{
					align-self: flex-end;
					width: 270px;
					margin-right: 8px;

					// Vertical line from
					&:before{
						content: '';
						position: absolute;
						top:-40px;
						right: 170px;
						height: 43px;
						width:3px;
						background: var(--theme-primary-dark);
						transition: background-color $animation-transition ease-in;

						// animation properties
						transform-origin: top;
						transform: scaleY(0);
					}

					// Right angeled line
					&:after{
						content: '';
						position: absolute;
						width: 170px;
						top:0;
						left: calc(100% - 170px);
						bottom:0;
						opacity: 0;
						z-index: -1;

						border-top:3px solid var(--theme-primary-dark);
						border-right:3px solid var(--theme-primary-dark);
						transition: 
							border-color $animation-transition ease-in,
							opacity 1ms 650ms linear; // 'dot' is present without  this work around

						// animation properties
						width:0;
						height: 3px; // needs to have the base height set at least to show line
					}

					h4{opacity: 0;}

					ul{
						visibility: hidden;
						overflow: hidden;

						li{
							transform: translateX(100%);
						}
					}
				}
			}
		}

		// Footer
		> footer{
			position: relative;
			height: 35px; // make sure main button is not cut off when scaled up when an expandable is visible
			margin:10px 30px 0;
			display: flex;
			justify-content: space-between;

			// Right Angles
			&:before,
			&:after{
				content: '';
				position: absolute;
				top:-10px;
				left:-15px;
				height: 25px;
				width: 15px;
				border-left:3px solid var(--theme-primary-dark);
				border-bottom:3px solid var(--theme-primary-dark);
				transition: 
					border-color $animation-transition ease-in
					opacity $animation-interaction ease-out;
			}
			&:after{
				left: auto;
				right:-15px;
				border-left: none;
				border-right: 3px solid var(--theme-primary-dark);

				// animation/revealed properties
				height:0;
				width:0;
				border-width: 0;
			}

			// CTAs/Tags
			> div{
				ul{
					display: flex;
					list-style: none;
					margin:0;

					li{
						position: relative;

						// Horizontal lines between items
						&:not(:first-child):before{
							content: '';
							position: absolute;
							z-index: -1;
							width:23px;
							height: 3px;
							top:50%;
							margin-top: -2px;
							left:-7px;
							background: var(--theme-primary-dark);
							transition: background-color $animation-transition ease-in;
						}

						// Skewed element so that the li hides the excess if needed (first item in list)
						> a,
						> span{
							position: relative;
							font-size: 13px;
							display: block;
							overflow: hidden;
							padding: 4px 30px;
							color: $color-white;
							transition: color $animation-transition ease-in;

							&:before,
							&:after{
								content: '';
								position: absolute;
							}

							// Skewed element shape
							&:before{
								top:0;right:15px;bottom:0;left:15px;
								transform: skew(-45deg);
								background: rgba(var(--theme-primary-rgb),.5);
								border: solid var(--theme-primary-dark);
								border-width: 2px 3px;
								z-index: -1;
								transition: 
									background-color $animation-transition ease-in,
									border-color $animation-transition ease-in;
							}
						}

						> span{
							&:before{background: rgba(var(--theme-primary-dark-rgb),.2);}
						}

						&.astralTooltipHover{
							> span:before{
								background: rgba(var(--theme-primary-dark-rgb),.5);
							}
						}
						
						// Link styles
						a:hover,
						a:focus{
							color: var(--theme-primary-txt);

							&:before{
								background: rgba(var(--theme-primary-rgb), 1);
							}
						}
					}
				}

				// CTA Specific Stylings
				&.ctas{
					transition: opacity $animation-interaction ease-out;

					ul{
						li{
							& + li{margin: 0 0 0 -10px;}

							// Set up left-skew to be out of box-bounds and thus hidden and create vertical line for the 'border'
							&:first-child{
								> *{
									padding: 4px 56px 4px 30px;

									&:before{left:-15px;}
									&:after{
										left:0;
										top:0;
										bottom: 0;
										width:2px;
										background: var(--theme-primary-dark);
										transition: background-color $animation-transition ease-in;
									}
								}
							}
						}
					}
				}

				// Tag Specific Styling
				&.tags{
					ul{
						flex-direction: row-reverse;
						li{
							white-space: nowrap;
							transform: scaleX(0);
							height: 0;
							overflow: hidden;

							& + li{margin: 0 -10px 0 0;}

							&:before{
								left: auto;
								right:-6px;

								// animation/revealed properties
								width: 0;
							}

							// Set up right-skew to be out of box-bounds and thus hidden and create vertical line for the 'border'
							&:first-child{
								> *{
									padding-right: 10px;

									&:before{right:-15px;}
									&:after{
										right:0;
										top:0;
										bottom: 0;
										width:2px;
										background: var(--theme-primary-dark);
										transition: background-color $animation-transition ease-in;
									}
								}
							}

							// Astral Tooltip (reset to prevent tooltip from breaking on lower devices)
							.astral-tooltip{
								white-space: normal;
							}
						}
					}
				}
			}
		}

		/* State: Opened ------------------------------------------------------------------------*/
			&.open{
				z-index: 10;

				// Trigger
				.trigger{
					color: var(--theme-primary-txt);

					h2,.utility{
						&:before{
							background: var(--theme-primary);
						}
					}

					.utility{
						.fas:before{
							transform: rotate(45deg);
						}
					}
				}

				// Content
					// remove full height line on right
					&:not(.loaded) > .content{
						border-right-color: transparent;
						transition: none; // due to hiding border-temporarily, we disable transition temporarily
					}

				> .content{
					position:relative;
					float:none;
					visibility:visible;
					z-index:0;
					width: auto;
					margin: 0 15px;

					&:after{transform: scaleY(1);}

					// Loading
					.comp-loading{

						// Horizontal lines
						&:before,
						&:after{
							transform: scaleX(1);
						}

						.inner_container{
							// Brackets
							&:before,
							&:after{
								transform: scaleY(1);
								width: 20px;
							}

							.spinner{opacity: 1;}
							p{
								animation: animateFade 1600ms alternate ease-in infinite;
							}
						}
					}
				}
			}

		/* State: Temporarily Opened (during ajax loading) --------------------------------------*/
			&.tmpOpen{
				// Trigger
				.trigger{
					color: var(--theme-primary-txt);

					h2,.utility{
						&:before{
							background: var(--theme-primary);
						}
					}

					.utility{
						.fas:before{
							transform: rotate(45deg);
						}
					}
				}

				// Content
				> .content{
					transition: none; // due to hiding border-temporarily, we disable transition temporarily

					// Animate loading-closed
					.comp-loading{
						height: 0;
						transition-delay: 650ms;

						// Horizontal lines
						&:before,
						&:after{
							animation: animateScaleX 200ms 450ms linear reverse forwards;
						}

						.inner_container{
							// Brackets
							&:before,
							&:after{
								animation:
									animateLoadingBracketsWidth 100ms 150ms linear reverse forwards,
									animateScaleY 200ms 250ms linear reverse forwards;
							}

							.spinner,
							p{
								animation: animateFade 250ms ease-in reverse forwards;
							}
						}
					}
				}
			}

		/* State: Opened and Loaded (projects are loaded via AJAX due to lots of media etc) -----*/
			&.open.loaded{

				// Content
				> .content{
					&:after{display: none;}

					// Slider & Video
					.comp-gallery,
					.comp-video{
						visibility: visible;

						&.comp-video{
							border: 3px solid var(--theme-primary-dark);
							background: rgba(0,0,0,1);
						}

						&:before{
							transform-origin: left;
							animation: animateScaleX 200ms 200ms forwards linear;
						}
						&:after{
							transform-origin: right;
							animation: animateScaleX 200ms 200ms forwards linear;
						}

						.wrapper,
						video{
							animation: animateFade 400ms 400ms forwards ease-in;
						}
						.btn-next,
						.btn-prev{
							animation: animateFade 200ms 800ms forwards ease-in;
						}
					}
				}
			}

		/* State: Animate Details ----------------------------------------------------------------*/
			&.js-animateDetails{
				// Content
				> .content{
					// Info Animations - PRE SCROLL MONITOR - add 200ms to delay}
					.info{ 
						// Details
						.details{
							animation: animateDetailsIn 500ms ease-in forwards,
						}

						// Clipart
						.clipart{
							animation: animateFade 350ms 500ms ease-in forwards;

							.connection{
								animation: animateScaleY 350ms 800ms linear forwards;
							}
						}
					}
				}
			}

		/* State: Animate Achievements ----------------------------------------------------------*/
			&.js-animateAchievements{
				> .content{
					.info{
						// Achievements - PRE SCROLL MONITOR - subtract 200ms from delay
						.comp-achievements{
							&:before{
								animation: animateScaleY 150ms 500ms linear forwards;

								// Phablet
								@include media-query(phablet){
									right: 120px;
								}
							}
							&:after{
								opacity: 1;
								animation: 
									animateAchievementLine2 150ms 650ms linear forwards,
									animateAchievementLine3 250ms 800ms linear forwards;

								// Phablet and down
								@include media-query(tablet-and-down){
									animation-name: animateAchievementLine2TabPhone, animateAchievementLine3;
								}

								// Phablet
								@include media-query(phablet){
									left: calc(100% - 120px);
									animation-name: animateAchievementLine2Phablet, animateAchievementLine3;
								}
							}

							h4{
								animation: animateFade 200ms 900ms ease-in forwards;
							}

							ul{
								visibility: visible;

								li{
									// Have to override whole animation and not just delay.. unclear why
									&:nth-child(1){animation: animateDetailsIn 200ms 1100ms ease-in forwards;}
									&:nth-child(2){animation: animateDetailsIn 200ms 1250ms ease-in forwards;}
									&:nth-child(3){animation: animateDetailsIn 200ms 1400ms ease-in forwards;}
								}
							}
						}
					}
				}
			}

		/* State: Animate Footer Items ----------------------------------------------------------*/
			&.js-animateFooterItems{

				// Footer
				> footer{
					&:after{
						border-width: 3px;
						animation: 
							animateTagRightAngleHeight 50ms 200ms linear forwards,
							animateTagRightAngleWidth 50ms 250ms linear forwards;
					}

					// Only animate height if no tags
					&.hasNoTags:after{
						animation: 
							animateTagRightAngleHeight 50ms 200ms linear forwards;
					}

					// Scale Main CTA Buttons - Phablet and up
					@include media-query(phablet-and-up){
						.ctas{
							ul {
								li.isLink{
									transform: scale(1.3);
									transform-origin: left center;
									transition: transform $animation-transition ease-in;

									&:nth-child(2){
										transform: scale(1.3) translateX(37px);
									}
								}
							}
						}
					}

					// Animate tags in
					.tags{
						ul li{
							height: auto;
							overflow: visible;
							transform-origin: right;

							&:nth-child(1){
								animation: animateScaleX 100ms 300ms linear forwards;
							}
							&:nth-child(2){
								animation: animateScaleX 100ms 450ms linear forwards;

								&:before{
									animation: animateTagSeparator 50ms 400ms linear forwards;
								}
							}
							&:nth-child(3){
								animation: animateScaleX 100ms 600ms linear forwards;

								&:before{
									animation: animateTagSeparator 50ms 550ms linear forwards;
								}
							}
							&:nth-child(4){
								animation: animateScaleX 100ms 750ms linear forwards;

								&:before{
									animation: animateTagSeparator 50ms 700ms linear forwards;
								}
							}
							&:nth-child(5){
								animation: animateScaleX 100ms 900ms linear forwards;

								&:before{
									animation: animateTagSeparator 50ms 850ms linear forwards;
								}
							}
						}
					}
				}
			}

		/* State: Tooltip Opened ----------------------------------------------------------------*/
			&.hasOpenTooltip{
				.trigger,
				.content,
				footer:before,
				footer:after,
				footer > div.ctas{
					opacity: .5;
				}
			}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Desktop
			@include media-query(desktop-and-down){
				> .content{
					.info{
						.clipart{
							left:0;
						}
					}
				}

				> footer{
					height: auto;
					flex-direction: column;
				}
				/* State: Animate Details - Hide the 'poping' caused by the margin */
					&.js-animateDetails{
						> footer{
							> div + div{margin-top: 20px;}
						}
					}

				/* State: Animate Footer Items */
					&.js-animateFooterItems{
						> footer{
							&:after{
								animation: 
									animateTagRightAngleHeightTall 50ms 200ms linear forwards,
									animateTagRightAngleWidth 50ms 250ms linear forwards;
							}

							// Only animate height if no tags
							&.hasNoTags:after{
								animation: 
									animateTagRightAngleHeight 50ms 200ms linear forwards;
							}
						}
					}
			}

			// Tablet and down
			@include media-query(tablet-and-down){
				.astral-expandables &{padding:0 15px;}

				&:before,
				&:after{
					width: 15px;
				}

				// Trigger
				.trigger{	
					h2,.utility{
						font-size: 24px;
						padding-top: 5px;
						padding-bottom: 7px;
					}
					h2{
						width: 80%;
					}
				}

				// Content
				> .content{
					padding: 40px 15px 30px;

					.comp-loading{
						&:before{left:-30px;}
						&:after{right:-30px;}
					}
					.comp-gallery{
						&:before{top:80px;}
						&:after{bottom:80px;}
					}
					.comp-video{
						&:before{bottom:60px;}
						&:after{top:60px;}
					}
					.info{
						.details{
							max-width: calc(100% - 18px);
						}
						.comp-text{
							&:before,
							&:after{
								width: 33px;
								left: -33px;
							}
						}
						.comp-achievements{
							&:before{
								right: 60px;
							}
							&:after{
								left: calc(100% - 60px);
							}
							ul li{background: rgba(var(--theme-primary-dark-rgb), .8);}
						}
					}
				}
				&.open > .content{margin: 0;}

				// Footer
				> footer{
					margin: 10px 15px 0;

					> div{
						ul li{
							> a{
								//&:before{background: var(--theme-primary);}
							}
						}

						&.ctas{
							ul{
								li{
									> a{
										//color: var(--theme-primary-txt);
									}
								}
							}
						}

						&.tags{
							ul{
								li{
									&:nth-child(4),
									&:nth-child(5){
										div.astral-tooltip{
											// overrides, unique implementation, important is to override JS injected values
											left:0 !important;
											right:auto !important;
										}
									}

									//> span:before{background: var(--theme-primary);}
								}
							}
						}
					}
				}
			}

			// Phablet and down
			@include media-query(phablet-and-down){

				.trigger{	
					h2,.utility{
						font-size: 20px;
						padding-top: 9px;
						padding-bottom: 9px;
					}
					h2{
						width: 85%;
					}
					.utility{
						width:50px;
						.fas{
							transform: translate(-8px, 3px);
						}
					}
				}

				// Content
				> .content{
					.comp-loading{
						&:before{left:-15px;}
						&:after{right:-15px;}
					}

 					.launch-modal_highlights{display: inline-block;}

					.comp-gallery{
						&:before{top:100px;}
						&:after{bottom:100px;}
					}
					.info{

						.comp-text{
							&:before,
							&:after{
								width: 18px;
								left: -18px;
							}
						}
					}
				}

				> footer{
					> div{
						&.tags{
							ul{
								li{
									> span{
										font-size: 12px;
										padding: 4px 26px;
									}
								}
							}
						}
					}
				}
			}

			// Phone
			@include media-query(phone){
				.astral-expandables &{padding:0 10px;}

				&:before,
				&:after{width:10px;}

				.trigger{
					h2{
						font-size: 16px;
						padding: 12px 20px 12px 10px;
						white-space: nowrap;
					}
					.utility{
						width: 35px;
						padding-right: 0;

						.fas{
							font-size: 14px;
							transform: translate(-14px, 0);
						}
					}
				}

				> .content{
					padding: 40px 10px 30px;

					.comp-video{
						&:before{bottom:40px;}
						&:after{top:40px;}
					}

					.info{
						.details{
							max-width: calc(100% - 13px);
						}
						.comp-achievements{
							width: 261px;

							ul{
								li{
									padding: 8px 5px 8px 50px;
									h6{
										font-size:12px;
										margin: -1px 0 2px;
									}
									p{font-size:10px;}
									.icon{
										left:5px;
									}
								}
							}
						}
					}
				}

				> footer{
					> div{
						ul{
							li{
								> a,
								> span{
									white-space: nowrap;
								}
							}
						}

						&.ctas{
							ul{
								li{
									&:first-child > *{
										padding: 4px 35px 4px 15px;
									}
								}
							}
						}

						&.tags{
							ul{
								li{
									&:before{
										right:0;
										animation-name: animateTagSeparatorPhone !important;
									}
									&:first-child>*{padding-right:5px;}
									span{
										font-size: 10px;
										padding: 3px 17px;
										&:before{
											left:8px;
											right:8px;
										}
									}
								}
							}
						}
					}
				}

				/* State: Animate Details - Hide the 'poping' caused by the margin */
					&.js-animateDetails{
						> footer{
							> div + div{margin-top: 24px;}
						}
					}
			}
	}

	/* Aniamtions -------------------------------------------------------------------------------*/
		@keyframes animateTagRightAngleHeight{
			100%{height: 25px;}
		}
		@keyframes animateTagRightAngleHeightTall{
			100%{height: 72px;}
		}
		@keyframes animateTagRightAngleWidth{
			100%{width: 15px;}
		}
		@keyframes animateTagSeparator{
			100%{width: 23px;}
		}
		@keyframes animateTagSeparatorPhone{
			100%{width: 9px;}
		}
		@keyframes animateDetailsIn{
			100%{
				opacity: 1;
				transform: translateX(0);
			}
		}
		@keyframes animateAchievementLine2{
			100%{width:170px;}
		}
		@keyframes animateAchievementLine2TabPhone{
			100%{width:60px;}
		}
		@keyframes animateAchievementLine2Phablet{
			100%{width:120px;}
		}
		@keyframes animateAchievementLine3{
			100%{height:100%;}
		}
		@keyframes animateHideFilteredExpandable{
			50%{opacity: 0;}
			100%{
				opacity: 0;
				height: 0;
				margin-bottom: -30px;
			}
		}
		@keyframes animateRevealFilteredExpandable{
			50%{
				height: 93px;
				margin-bottom: 0;
			}
			100%{
				height: 93px;
				margin-bottom: 0;
				opacity: 1;
			}
		}
		@keyframes animateLoadingBracketsWidth{
			0%{width:3px;}
			100%{width: 20px;}
		}
