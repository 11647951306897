/*-------------------------------------------------------------------------------------------------
Pager
-------------------------------------------------------------------------------------------------*/
	.comp-pager{
		margin-top: 22px !important;

		ul{
			text-align: center;
			margin: 0;
			list-style: none;

			li{
				display: inline-block;
				margin:0 5px;
				span{
					display: block;
					width: 20px;
					height: 20px;
					cursor: pointer;
					border: 2px solid var(--theme-primary-dark);
					background: rgba(var(--theme-primary-rgb), .2);
					transition: 
						border-color $animation-interaction ease-in,
						background-color $animation-interaction ease-in;
				}
				span:focus,
				span:hover,
				&.active span{
					background: var(--theme-primary);
				}
			}
		}
	}
