// Variables & Mixins
@import '../base/variables';
@import '../mixins/mixins';

/*-------------------------------------------------------------------------------------------------
Astral Modal
-------------------------------------------------------------------------------------------------*/

	// Overlay
	.astral-modal-overlay{
		position:fixed;top:0;left:0;bottom:0;right:0;z-index:1000;
		animation: animateModalOverlayIn $animation-interaction ease-in forwards;
		min-height: 100vh; // mobile adjustment to not 'pop down' on scroll when address bar shrinks
	}

	// Container
	.astral-modal-container{
		position:absolute;
		left:50%;
		top: 75px; /* Default Position if too large for browser window */
		width: 76%;
		margin-left: -38%;
		color: var(--theme-primary-txt);
		background: rgba(var(--theme-primary-rgb), 0);
		border:3px solid rgba(var(--theme-primary-dark-rgb),0);
		z-index: 1001;
		animation: animateModalContainerIn $animation-transition 600ms ease-in forwards;

		transition: 
			border-color $animation-transition ease-in,
			color $animation-transition ease-in,
			background-color $animation-transition ease-in;

		&:focus{outline: none;}
	}

	// Content
	.astral-modal-content{
		position:relative;

		.astral-modal-close{
			position:absolute;
			top:8px;
			right:15px;
			border:none;
			z-index:10;
			cursor:pointer;
			background: $color-gray-dark;
			color: $color-white;
			display: block;
			padding: 5px 7px 4px;
			line-height: 100%;
			font-size: 24px;
			transition: 
				background-color $animation-interaction ease-in,
				color $animation-interaction ease-in;

			&:hover,
			&:focus{
				color:$color-gray-dark;
				background:$color-white;
			}
		}

		.modal-header{
			position: relative;
			color: var(--theme-primary-txt);
			padding: 7px 60px 7px 15px;
			font-size: 23px;
			line-height: 35px;
			background: var(--theme-primary);
			border-bottom: 3px solid var(--theme-primary-dark);
		}

		.modal-content{padding: 15px;}
	}

	/* Variants ---------------------------------------------------------------------------------*/

		/* Resume -------------------------------------------------------------------------------*/
			.astral-modal-container.modal-resume{
				top: 75px !important;
				width: $container-large + 6;
				margin: 0 0 0 (-($container-large * .5) - 3);
				opacity: 0;
				pointer-events: none; // allows click-through of spacing below modal(i.e. :after which is needed for FF)

				border: none;
				animation: animateFade $animation-interaction 150ms ease-in forwards;

				&:after{
					content: '';
					display: block;
					width: 100%;
					height: 75px;
				}

				// Keep spacing in ff with this work around
				.astral-modal-content{
					color: $color-gray-dark;
					background: $color-white;
					border: 3px solid var(--theme-primary-dark);
					pointer-events: auto;

					transition: 
						border-color $animation-transition ease-in,
						color $animation-transition ease-in,
						background-color $animation-transition ease-in;
				}

				.modal-header{
					transition: 
						background-color $animation-transition ease-in,
						border-color $animation-transition ease-in;

					// Printer Friendly link
					a:link,
					a:visited{
						position: relative;
						top: 1px;
						font-weight: normal;
						color: var(--theme-primary-txt);
					}
					a:focus,
					a:hover{color: $color-gray-dark;}
				}

				.modal-content{
					padding: 15px 15px 30px;

					svg{
						.logo-jh{
							fill: $color-gray-dark;
						}
					}
				}

				// When page is in a modal, cancel out the width since the modal dictates
				#page-resume{
					margin: 0 auto;
					width: auto;
				}

				/* Responsive Updates -----------------------------------------------------------*/

					// Desktop
					@include media-query(desktop){
						width: $container-desktop + 6;
						margin: 0 0 0 (-($container-desktop * .5) - 3);
					}

					// Tablet
					@include media-query(tablet){
						top: 20px !important;
						width: $container-tablet + 6;
						margin: 0 0 0 (-($container-tablet * .5) - 3);

						&:after{
							height: 20px;
						}
					}

					// Phablet and Down
					@include media-query(phablet-and-down){
						width: calc(100% - 30px);
						top: 15px !important;
						left: 15px;
						margin: 0;

						&:after{
							height: 15px;
						}
					}

					// Phone
					@include media-query(phone){
						width: calc(100% - 20px);
						top: 10px !important;
						left: 10px;
						margin: 0;

						&:after{
							height: 10px;
						}
					}
			}

			.theme-yellow{
				.astral-modal-container.modal-resume{
					.astral-modal-content{
						color: $color-white;
						background: $color-black;

						.modal-content{
							svg{
								.logo-jh{
									fill: $color-white;
								}
							}
						}
					}
				}
			}
			.theme-white{
				.astral-modal-container.modal-resume{
					.astral-modal-content{
						color: var(--theme-primary-dark);
						background: $color-black;

						.modal-content{
							svg{
								.logo-jh{
									fill: var(--theme-primary);
								}
							}
						}
					}
				}
			}

		/* Animated -----------------------------------------------------------------------------*/
			.astral-modal-container.animated{
				&:before,
				&:after{
					content: '';
					position: absolute;
					top:50%;
					margin-top: 1px;
					height: 3px;
					background: var(--theme-primary-dark);
					width: calc(16.5% - 23px);
					transform: scaleX(0);
					animation: animateScaleX $animation-interaction 150ms linear forwards;
				}
				&:before{
					right: calc(100% + 23px);
					transform-origin: left;
				}
				&:after{
					left: calc(100% + 23px);
					transform-origin: right;
				}

				.astral-modal-content{
					&:before,
					&:after{
						content: '';
						position: absolute;
						width: 3px;
						top: -23px;
						bottom:-23px;
						border:3px solid var(--theme-primary-dark);
						transform: scaleY(0);
						animation:
							animateScaleY $animation-interaction 400ms linear forwards,
							animateLoadingBracketsWidth 150ms 650ms linear forwards;
					}
					&:before{
						left: -23px;
						border-right: none;
						transform-origin: left center;
					}
					&:after{
						right: -23px;
						border-left: none;
						transform-origin: right center;
					}

					> div,
					> button{
						opacity: 0;
						animation: animateFade $animation-transition 600ms ease-in forwards;
					}
				}
			}

		/* Misc Utility -------------------------------------------------------------------------*/
			.astral-modal-container.isForcedTop{top:80px !important;}
			.astral-modal-container.highlights{
				.modal-content{
					ul{margin-left: 10px;}
				}
			}
			

	/* Animations -------------------------------------------------------------------------------*/
		@keyframes animateModalOverlayIn{
			0%{background-color: rgba($color-black,0);}
			100%{background-color: rgba($color-black,.95);}
		}
		@keyframes animateModalContainerIn{
			100%{
				background: rgba(var(--theme-primary-rgb), .2);
				border-color: var(--theme-primary-dark);
			}
		}
