/*-------------------------------------------------------------------------------------------------
Fonts
-------------------------------------------------------------------------------------------------*/
	@font-face {
		font-family: 'MyWebFont';
		src: url('webfont.eot'); /* IE9 Compat Modes */
		src: url('webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
			url('webfont.woff') format('woff'), /* Modern Browsers */
			url('webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
			url('webfont.svg#svgFontName') format('svg'); /* Legacy iOS */
	}
