// Variables & Mixins
@import '../base/variables';
@import '../mixins/mixins';

/*-------------------------------------------------------------------------------------------------
Astral Gallery: Shuffle
-------------------------------------------------------------------------------------------------*/
	.astral-shuffle{
		margin: 30px 0;
		position:relative;

		// Headings
		.headings{
			position: relative;
			z-index:10;
			margin-bottom: 10px;

			> ul{
				margin: 0;
				list-style: none;

				> li{
					display: none;
					text-align: center;

					& + li{margin-top:0;}

					a{
						color: $color-white;
						text-decoration: none;
						position: relative;
					}

					&.active {
						display: block;
					}
				}
			}
		}

		// Images 
		.images{
			position:relative;
			padding: 0 15px;

			> ul{
				position:relative;
				z-index:0;
				list-style: none;
				display: flex;
				justify-content: center;
				margin: 0;

				> li{
					position: absolute;
					top: 0;
					transition: 
						opacity $animation-transition ease-in, 
						transform $animation-transition ease-in;

					&:first-child{z-index: 10000;} /* keep 1st item on top during initialization */ 
					& + li{margin:0;}

					a:before,
					a:after{display: none;}

					img{
						border:2px solid var(--theme-primary-dark);
						transform-origin: 0 50%;
						width: 100%;
						transition: 
							transform $animation-transition ease-in, 
							opacity $animation-transition ease-in,
							border-color $animation-transition ease-in,
							box-shadow $animation-transition ease-in;
					}
					&.active{
						position: relative;
						transform: translate(0);
						img{opacity: 1;}
					}

					&:nth-child(2),
					&:nth-child(3){ img{ opacity: .6 } }

					&:nth-child(4),
					&:nth-child(5){ img{ opacity: .2 } }

					&.odd{
						img{transform-origin: 100% 50%;}
					}
				}
			}
		}

		/* Default Responsive Changes -----------------------------------------------------------*/
			// Larger Devices
			@media only screen and (min-width: 640px){
				// Images
				&.astralInitialized .images > ul > li img{
					animation: astralShuffleFadeDropshadow .3s ease-in;
					-webkit-box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),.3);
					box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),.3);
				}
			}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Desktop
			@include media-query(desktop-and-down){
				.images{
					padding: 0 60px;
				}
			}

			// Phone
			@include media-query(phone){
				.headings{
					h2{font-size: 24px;}
				}
				.images{
					padding: 0;
					> ul{

						> li{
							transform: none !important;

							img{transform: scale(1) !important;}

							&.active{
								position: relative;

								img{
									-webkit-box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),.3);
									box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),.3);
								}
							}
						}
					}
				}
			}
	}

	/* Animations -------------------------------------------------------------------------------*/
		@keyframes astralShuffleFadeDropshadow{
			0%{
				-webkit-box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),0);
				box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),0);
			}
			100%{
				-webkit-box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),.3);
				box-shadow: 0 0 10px 3px rgba(var(--theme-primary-rgb),.3);
			}
		}
