/*-------------------------------------------------------------------------------------------------
Page States

This file holds various transitions for various states the page may be in. 
I've opted to contain all elements that are affected by a particular state within this file. It is easier to see all the items affected rather than bouncing between pages and trying to maintain it all in head.
/*-----------------------------------------------------------------------------------------------*/
	html{

		/* Navigation ---------------------------------------------------------------------------*/
			// Has Stuck Header
			&:not(.noSiteLines).headerState-isStuck{
				// Sitelines
				#wrapper:before, // Left Line
				#wrapper:after, // Right Line 
				#content:before, // Mid Line
				#template:before, // Left Line(fades out)
				#template:after, // Right Line (fades out)
				#content:after{ // Mid Line (fades out)
					top: 70px;
				}

				#wrapper{
					padding-top: 70px;
				}
			}

			// From Sidebar to Topbar
			&.headerState-isSticking{
				// Header
				#header{
					animation: 
						animateHeaderPosition $animation-transition linear,
						animateHeaderFadeIn $animation-transition ease-in;
				}
			}

			// From Topbar to Sidebar
			&:not(.noSiteLines).headerState-isSidebaring{
				// Header
				#header{
					animation: 
						animateHeaderPosition $animation-transition linear reverse,
						animateFade $animation-transition reverse ease-in;
				}

				// Sitelines
				#wrapper:before, // Left Line
				#wrapper:after, // Right Line 
				#content:before, // Mid Line
				#template:before, // Left Line(fades out)
				#template:after, // Right Line (fades out)
				#content:after{ // Mid Line (fades out)
					top: 0;
				}

				#wrapper{
					padding-top: 0;
				}
			}

			// Fade In
			&.headerState-isFading{
				// Header
				#header{
					animation: animateHeaderFadeIn $animation-transition ease-in;
				}
			}

			/* Animations -----------------------------------------------------------------------*/
				// Transitioning into Topbar
				@keyframes animateHeaderPosition{
					0%{transform: translateY(-67px);}
					100%{ transform: translateY(0); }
				}
				// HeaderFadeIn
				// Had to duplicate the 'fade' because otherwise JS events based on the same name would fire when not-intended
				@keyframes animateHeaderFadeIn{
					0%{opacity: 0;}
					100%{opacity: 1;}
				}

			/* Responsive Updates ---------------------------------------------------------------*/
				// Phablet and Down
				@include media-query(phablet-and-down){
					#wrapper{padding-top:70px;}
				}

		/* Page is Loading ----------------------------------------------------------------------*/
			&.pageState-isLoading{
				#wrapper:before,
				#wrapper:after,
				#content:before,
				#header,
				#content .page{
					opacity: 0;
					display: none;
				}
			}

		/* Page is loaded -----------------------------------------------------------------------*/
			&.pageState-isLoaded{
				// Site Lines
				#wrapper{
					&:before, // Left Line
					&:after, // Right Line 
					#content:before{ // Mid Line
						animation: animateFade $animation-transition ease-in;
					}
				}

				// Header
				#header{
					animation: animateHeaderFadeIn $animation-transition ease-in;
				}
			}

		/* Theme Change -------------------------------------------------------------------------*/
			&.isThemeTransitioning:not(.isEdge){
				// Site Lines
				&:not(.noSiteLines){
					#wrapper{
						&:before, // Left Line
						&:after, // Right Line 
						#content:before{ // Mid Line
							//animation: animateFade $animation-transition ease-in;
						}
						
						#template:before, // Left Line(fades out)
						#template:after, // Right Line (fades out)
						#content:after{ // Mid Line (fades out)
							animation: animateFadeSiteLines $animation-transition reverse ease-in;
						}
					}
				}

				// Navigation - Fake gardiant fade by cross-fading previous color-theme and current
				nav a.isActive .icon,
				nav input:checked + label .icon{
					&:after{
						animation: animateFade $animation-transition reverse ease-in;
					}
				}

				// Horizontal Rules
				hr,.hr{
					&:after{
						animation: animateFade $animation-transition reverse ease-in;
					}
				}
			}
	}

	/* Animations -------------------------------------------------------------------------------*/
		@keyframes animateFadeSiteLines{
			0%{display: none;}
			50%{display: block;}
			100%{display:block;opacity: 1;}
		}
