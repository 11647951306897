/*-------------------------------------------------------------------------------------------------
Horizontal Rules
/*-----------------------------------------------------------------------------------------------*/
	hr, .hr{
		display: block;
		position: relative;
		height: 3px;
		border: 0;
		margin: 3px 0 10px;
		background: var(--theme-primary);
		background: linear-gradient(to right, 
			rgba(var(--theme-primary-rgb), 1) 0,
			rgba(var(--theme-primary-rgb), 0) 80%
		);

		/*
			This is used in order to make a gradiant appear to fade in when the theme changes
		*/
		&:after{
			content: '';
			height: 3px;
			width: 100%;
			position: absolute;
			top:0;
			opacity: 0;
			background: linear-gradient(to right, 
				rgba(var(--theme-prev-primary-rgb), 1) 0,
				rgba(var(--theme-prev-primary-rgb), 0) 80%
			);
		}
	}
