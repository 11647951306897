/*-------------------------------------------------------------------------------------------------
Quotes
-------------------------------------------------------------------------------------------------*/
	.comp-quotes{
		position: relative;
		margin-bottom: 50px;

		// Left/Right Lines
		&:before,
		&:after{
			content: '';
			position: absolute;
			height: 3px;
			width: 30px;
			background: var(--theme-primary-dark);
			transition: background-color $animation-transition ease-in;
		}
		&:before{
			top:0;
			left:-30px;
		}
		&:after{
			right:-30px;
			bottom:0;
		}

		// Right Corners
		.wrapper{
			position: relative;
			background: rgba($color-black, 0);
			transition: background-color $animation-interaction ease-in;

			&:before,
			&:after{
				content: '';
				position: absolute;
				width: 50px;
				height: 50px;
				z-index: 0;

				transition: 
					width $animation-interaction linear,
					height $animation-interaction linear,
					border-color $animation-transition ease-in;
			}
			&:before{
				top:0;
				left:0;
				border-top: 3px solid var(--theme-primary-dark);
				border-left: 3px solid var(--theme-primary-dark);
			}
			&:after{
				bottom:0;
				right:0;
				border-right: 3px solid var(--theme-primary-dark);
				border-bottom: 3px solid var(--theme-primary-dark);
			}

			&:hover{
				background: rgba($color-black, .5);

				&:before,
				&:after{
					width: 100%;
					height: 100%;
				}
			}
		}

		// Cycle
		.cycle{
			margin:0;
			list-style: none;

			// Quotation Marks
			&:before,
			&:after{
				position: absolute;
				font-family: sans-serif;
				color: var(--theme-primary);
				padding: 10px;
				width: 50px;
				height: 50px;
				font-size: 80px;
				line-height: 75px;
				z-index: 0;
				transition: color $animation-transition ease-in;
			}

			&:before{
				content: open-quote;
				top:0;
				left:0;
			}
			&:after{
				content: close-quote;
				right: 0;
				bottom: 0;
			}

			// Force vertical alignment of quotes
			li{
				display: flex !important;
				height: 100%;
				width: 100%;
				& + li{margin:0;}

				blockquote{
					display: flex;
					flex-direction: column;
					width: 100%;
					height: 100%;
					justify-content: center;
				}
			}
		}

		// Pager
		.comp-pager{
			position: absolute;
			width: 100%;
			bottom: -40px;
		}
	}
