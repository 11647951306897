/*-------------------------------------------------------------------------------------------------
Page Template: Resume
/*-----------------------------------------------------------------------------------------------*/
	#page-resume{
		width: $container-large;
		margin: 20px auto 30px;

		// Structure
		> div{
			// Header and section spacing
			h2 + div{margin-top: 10px;}
			+ div{margin-top: 30px;}

			// Inner section spacing
			> div{
				margin-left: 30px;

				& + div{
					margin-top: 20px;
				}
			}
		}

		// General Overrides
			ul{
				li + li{margin-top: 5px;}
			}
			a:hover,
			a:focus{
				color: var(--theme-primary-dark);
			}

		// Basic Info
		header{
			margin-bottom: 20px;
			padding-bottom: 10px;
			display: flex;
			justify-content: space-between;
			border-bottom: 1px solid var(--theme-primary);
			transition: border-color $animation-transition ease-in;

			h1{
				color: var(--theme-primary);
				transition: color $animation-transition ease-in;
			}
			h1 + h4{margin-top:-4px;}

			ul{
				margin-top:4px;
				list-style: none;
				li{
					display: block;
					text-align: right;

					& + li{margin-top:-1px;}
				}
			}

			.logo{
				position: relative;
				top:10px;
				svg{
					width: 75px;
				}
			}
		}

		// Employment
		.employment{
			// dates
			.float-right{
				font-size: 15px;
				font-weight: normal;
			}
		}

		// Skills
		.skills{
			> div{
				display: flex;

				ul:not(:first-child){
					margin-left: 60px;
				}
			}
		}

		// Info (name/etc on left, misc date/info on right
		.info{
			> div{
				display: flex;
				justify-content: space-between;

				> div{
					margin: 0;
					text-align: right;
				}
			}
		}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Tablet
			@include media-query(tablet){
				> div{
					> div{
						margin-left: 15px;
					}
				}

				.employment{
					h3{
						.float-right{
							display: block;
							top: -5px;
							float:none;
							position: relative;
						}
					}
				}

				.skills{
					> div{
						ul:not(:first-child){
							margin-left: 40px;
						}
					}
				}
			}

			// Phablet
			@include media-query(phablet-and-down){
				> div{
					> div{margin-left: 0;}
				}

				header{
					flex-direction: column;

					div{width: 100%;}
					div:nth-child(2){display: none;}

					ul{
						margin: 0;
						li{text-align: left;}
					}
				}

				.employment{
					h3{
						span > small,
						em{
							display: block;
						}
						.dash{display: none;}
						.float-right{
							top: -5px;
							float:none;
							position: relative;
						}
					}
				}

				.skills{
					> div {
						flex-direction: column;

						ul{
							margin-left: 30px !important;
							display: flex;
							& + ul{margin-top:5px;}

							li{
								width:50%;
								& + li{margin: 0;}
							}
						}
					}
				}

				.info{
					> div{
						> div{display: none;}
					}
					p{
						> span{display: block;}
						em{
							display: block;
						}
					}
				}
			}

			// Phone
			@include media-query(phone){
				.skills{
					> div{
						ul{
							flex-direction:column;
							li{
								width:100%;
								& + li{margin-top: 5px;}
							}
						}
					}
				}
			}
	}

	/* In Browser Print -------------------------------------------------------------------------*/
	.print{
		transition: background-color $animation-transition ease-in;

		#page-resume{
			h1{color: var(--theme-primary);}
			h2, h3, h4, p, ul{color: $color-black;}
			.logo .logo-jh,
			.logo svg path[class*="logo-bracket"]{fill: $color-black;}
		}

		/* White Theme */
			.theme-white &{
				background: $color-black;

				#page-resume{
					h2, h3, h4, p, ul{color: var(--theme-primary-dark);}
					.logo .logo-jh,
					.logo svg path[class*="logo-bracket"]{fill: $color-white;}
				}
			}
	}
