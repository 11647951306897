/*-------------------------------------------------------------------------------------------------
Multicols: Assumes 2% gap between columns but none on outer edges
-------------------------------------------------------------------------------------------------*/
	.comp-multicols{
		display: flex;
		position:relative;
		justify-content: space-between;

		&.cols2 .col{width:49%;}
		&.cols3 .col{width:32%;}
		&.cols4 .col{width:23.5%;}
		&.cols5 .col{width:18.4%;}
		&.cols6 .col{width:15%;}
		&.cols7 .col{width:12.571%;}
		&.cols8 .col{width:10.75%;}
		&.cols9 .col{width:9.333%;}
		&.cols10 .col{width:8.2%;}


		/* Responsive Changes -------------------------------------------------------------------*/

			// Phone
			@include media-query(phone){
				flex-direction: column;

				&[class*="cols"] > .col{width: 100%;}
			}
	}
