/*-------------------------------------------------------------------------------------------------
Page Template: Projects
/*-----------------------------------------------------------------------------------------------*/
	#page-projects{

		> .sidebar{

			// Filters
			#filters{

				&.isHiding{
					animation: animateFade $animation-transition reverse ease-in;
				}

				&.isRevealing{
					animation: animateFade $animation-transition ease-in;
				}

				li{overflow: hidden;}

				#subfilters{
					&.isHiding{
						animation: animateFade $animation-transition reverse ease-in;
					}

					&.isRevealing{
						animation: animateFade $animation-transition ease-in;
					}
				}
			}

			// Highlights
			#highlights{
				position: absolute;
				width: 270px;
				top:0;

				transform: translateX(100%);
				opacity: 0;
				transition: 
					opacity $animation-transition ease-out,
					transform $animation-transition ease-in;

				&.isVisible{
					opacity: 1;
					transform: translateX(0);
				}

				h5{margin-bottom:4px;}

				// Component: Text overrides
				.comp-text{
					width: 100%;
					margin: 0;

					&:before,
					&:after{
						width: 15px;
						right:-18px;
					}
					&:before{top: 20px;}
					&:after{bottom: 20px;}

					> ul{
						margin-left: 5px;
					}
				}
			}
		}

		// Edge Requires this otherwise the subfilters doesn't fade in
		&.isActive > .sidebar{animation: animateFade $animation-transition ease-in;}


		/* Responsive Updates -------------------------------------------------------------------*/

			// Tablet
			@include media-query(tablet){
				> .sidebar{
					#highlights{
						width:230px;
					}
				}
			}

			// Phablet and down
			@include media-query(phablet-and-down){
				> .sidebar{
					display: none;
				}
			}
	}
