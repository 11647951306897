/*-------------------------------------------------------------------------------------------------
Header
/*-----------------------------------------------------------------------------------------------*/
	#header{
		position: fixed;
		width: 300px;
		padding: 0 15px;
		top: 0;
		left: 50%;
		bottom: 0;
		transform: translateX(-$container-large * .5);
		z-index: 1000;

		// Remove fixed for small-height devices
		@media screen and (max-height: 500px){
			position: relative;
			bottom: auto;
			float: left;
		}

		// Not Stuck Overrides
		html:not(.headerState-isStuck) &{
			.container{width:100%;}

			.comp-multicols{
				flex-direction: column;

				div:nth-child(1){margin:50px 0;}
				div:nth-child(2){margin-bottom:20px;}
			}
		}

		// Stuck to Top
		.headerState-isStuck &{
			position: sticky;
			position: -webkit-sticky;
			left:0;
			right:0;
			bottom: auto;
			width: 100%;
			height: 67px;
			margin-top: -70px;
			padding: 11px 15px;
			transform: none;
			float: none;
			background: rgba($color-black, .8);
			border-bottom: 3px solid var(--theme-primary);
			transition: border-color $animation-transition ease-in;

			// Multicolumns Overrides
			.comp-multicols{
				margin: 0 15px;
				align-items: center;
				justify-content: left;

				.col{
					&:first-child{margin-right:30px;}
					&:last-child{margin-left:auto;}
				}
			}

			// Logo Overrides
			.logo{
				svg{
					width: 50px;
				}
			}

			// Navigation Overrides
			#navigation{
				ul{
					display: flex;
					li{
						height: auto;
						margin:0;
						
						& + li{
							margin:0 0 0 30px;
						}

						a:link,
						a:visited{
							background: none;

							> span:before,
							> span:after{display: none !important}

							&:hover,
							&:focus{
								color: var(--theme-primary);
								background: none;
							}

							&.isActive{
								background: var(--theme-primary);
								color: var(--theme-primary-txt);
								transition: 
									color $animation-transition ease-in,
									background-color $animation-transition ease-in;
							}
						}
					}
				}
			}

			// Utilities
			#utilities{
				.btn-icon{
					background: none;
				}
			}
		}

		// Due to white-on-white, we need some special handling for this theme
		.headerState-isStuck.theme-white &{
			#navigation{
				ul{
					li{
						a:hover,
						a:focus{
							color: #888;
							&.isActive{
								color: var(--theme-primary-txt);
							}
						}
					}
				}
			}

			#utilities{
				.btn-icon{
					&:hover,
					&:focus{
						color: #888;
					}
				}
			}
		}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Desktop
			@include media-query(desktop){
				transform: translateX(-$container-desktop * .5);
			}

			// Tablet
			@include media-query(tablet){
				width: 250px;
				transform: translateX(-$container-tablet * .5);

				// Stuck to Top
				.headerState-isStuck &{
					width: 100%;

					// Columns Spacing
					.comp-multicols{
						.col:first-child{margin-right:24px;}
					}

					// Navigation
					#navigation{
						ul{
							li + li{
								margin-left: 12px !important;
							}
						}
					}
				}

				// Navigation
				#navigation{
					ul{
						li{font-size: 20px;}
					}
				}
			}

			// Phablet and Down
			@include media-query(phablet-and-down){
				// Force header to be stuck; a lot is duplicate but it is to override both states rather than trying to manage classes via js especially when user could have a device that has orientation
				position: fixed;
				left:0;
				right:0;
				bottom: auto;
				width: 100%;
				height: 67px;
				float: none;
				padding: 11px 15px;
				transform: none;
				background: rgba($color-black, .9) !important;
				border-bottom: 3px solid var(--theme-primary);

				// Reset container regardless of state
				.container{width: calc(100% - 30px) !important;}

				// Multicols overrides
				.comp-multicols{
					flex-direction: row !important;
					align-items: center;
					margin: 0 !important;

					div:nth-child(1){margin: 0 !important;}
					div:nth-child(2){display: none;margin:0;}
				}

				// Logo Overrides
				.logo{
					svg{
						width: 50px;
						margin: 0;
					}
				}

				// Utilities
				#utilities{
					.btn-icon{
						background: none;
					}
				}
			}

			// Phone
			@include media-query(phone){
				// Reset container regardless of state
				.container{width: 100% !important;}
			}
	}

	/* Logo -------------------------------------------------------------------------------------*/
		.logo{

			&:focus{
				&:before,
				&:after{display: none;}
			}

			svg{
				width: 140px;
				display: block;
				margin: 0 auto;

				path{fill: $color-white;}
				path[class*="paren"]{
					fill: var(--theme-primary);
					transition: fill $animation-transition ease-in;

					-webkit-transform-origin: 50% 50%;
					-moz-transform-origin: 50% 50%;
					-ms-transform-origin: 50% 50%;
					-o-transform-origin: 50% 50%;
					transform-origin: 50% 50%;
				}
				path[class*="bracket"]{fill: $color-gray;}
			}

			// Logo Animation
			&.js-isAnimating{
				path[class*="jh"]{
					opacity: 0;
					animation: animateFade 500ms 800ms 1 ease-out forwards;
				}
				path[class*="paren"]{
					opacity: 0;
					animation: 
						animateRotateY360 1200ms 250ms 1 ease-out, 
						animateFade 1200ms 250ms 1 ease-out forwards;
				}
				path[class*="bracket"]{
					animation: animateFade 75ms 7 alternate-reverse linear;
				}
			}
		}

	/* Navigation -------------------------------------------------------------------------------*/
		#navigation{
			ul{
				li{
					font-size: 22px;
					text-transform: uppercase;

					a:link,
					a:visited{
						height: 34px;
						line-height: 32px;
						padding: 1px 10px 3px;
					}

					.icon{
						margin-top:6px;
						> span{
							display: block;
							top:3px;

							&.fa-paper-plane{left:-1px;}
						}
					}
				}
			}
		}

	/* Utilities --------------------------------------------------------------------------------*/
		#utilities{
			display: flex;
			list-style: none;
			justify-content: flex-end;
			margin: 0;

			li{
				margin-left: 20px;

				&:last-child{
					display: none;
				}
				& + li{margin-top:0;}
			}

			/* Responsive Updates ---------------------------------------------------------------*/

				// Phablet and Down
				@include media-query(phablet-and-down){
					li{
						&:last-child{
							display: block;
						}
					}
				}
		}
