// Variables & Mixins
@import '../base/variables';
@import '../mixins/mixins';

/*-------------------------------------------------------------------------------------------------
Astral Select
-------------------------------------------------------------------------------------------------*/
	.astral-select{
		position:relative;

		// Button / Toggle
		> .toggle{
			color: $color-white;
			font-size: 18px;
			padding:4px 30px 4px 10px;
			text-align: left;
			position: relative;
			width: 100%;
			max-width: 400px;
			background: rgba($color-black, .2);
			border: 2px solid var(--theme-primary-dark);

			transition: 
				border-color $animation-interaction ease-in,
				background-color $animation-interaction ease-in;

			&:focus,
			&:hover{
				outline: none;
				background: rgba($color-black, .5);
				border-color: var(--theme-primary);

				&:before{
					background: var(--theme-primary);
				}
			}
			&:focus{
				background: $color-black;
			}

			// line:
			&:before{
				content: '';
				position: absolute;
				top:0;
				right:30px;
				bottom:0;
				width:2px;
				background: var(--theme-primary-dark);
				transition: background-color $animation-interaction ease-in;
			}

			// Arrow
			&:after{
				content: '\f078';
				display: block;
				position: absolute;
				top:50%;
				right:7px;
				margin-top:-10px;
				font-size: 18px;
				font-weight: 900;
				font-family: $font-awesome;
				color: $color-white;
			}
		}

		// Disabled
		&.disabled {
			> .toggle,
			> .toggle:hover,
			> .toggle:focus {
				background:#eee;
				border-color:#ccc;
				cursor:default;
				color:#aaa;
			}
		}

		// Options
		> .options{
			border: 2px solid var(--theme-primary);
			border-top:none;
			background: $color-black;
			position: absolute;
			top:33px;
			left: 0;right:0;
			height: 182px;
			overflow: auto;
			z-index: 100;
			display:none;
			max-width: 400px;

			> ul{
				position:relative; /* REQUIRED */
				list-style:none;
				margin:0;

				> li{
					& + li{margin-top:0;}
					> a{
						color: $color-white;
						display: block;
						padding: 2px 10px 1px;
						text-decoration: none;
						font-size:18px;
					}

					> a:focus,
					> a:hover{
						background: rgba(var(--theme-primary-rgb), .5);

						// hide focus items
						&:before,
						&:after{display: none;}
					}
					&.highlight > a{background: var(--theme-primary);}
				}
			}
		}

		// Opened
		&.open{
			> .toggle{
				background: $color-black;
				transition-duration: 0;
			}
			> .options{
				display: block;
			}
		}
	}
