/*-------------------------------------------------------------------------------------------------
Typography
/*-----------------------------------------------------------------------------------------------*/
	html{
		overflow-x: hidden; // needed for animated modals
		overflow-y: scroll; // force vert scrollbar to prevent page from 'shifting' if doesnt reach 100% height
	}
	#wrapper{ // android fix for address bar affecting body/html height
		min-height: 100vh; 
		-webkit-backface-visibility: hidden;
		background: 
			var(--theme-bg-flash) top center no-repeat fixed,
			url('../media/images/bg-starfield.png') top center,
			linear-gradient(to bottom, var(--theme-secondary) 0,  $color-black 2000px) fixed;

		// Edge forces us to 'fix' the starfield because(unanswered but others can reproduce):
		// https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11985044/
		.isEdge &{
			background-attachment: fixed, fixed, fixed;
		}
		transition: padding-top $animation-transition linear;
		padding-bottom: 70px; // account for footer
	}

	body{
		font-size: 15px;
		color: $color-white;
		line-height: $line-height;
		font-family: $font-family;
	}

	::-moz-selection,
	::selection{
		color: var(--theme-primary-txt); 
		background-color: var(--theme-primary);
	}

	p{
		& + *{margin-top: 20px;}
	}
	
	/* Headings ---------------------------------------------------------------------------------*/
		h1{
			font-size: 30px;
		}
		h2{
			font-size: 27px;
		}
		h3{
			font-size: 24px;
		}
		h4{
			font-size: 20px;
			small em{font-weight: normal;}
		}
		h5{
			font-size: 18px;
		}
		h6{
			font-size: 14px;
		}

	/* Blockquote -------------------------------------------------------------------------------*/
		blockquote{
			position: relative;
			padding: 15px 70px;

			footer{
				position: relative;
				margin-top:0;
				z-index: 2;
				text-align: right;
			}

			p{font-size: 20px;}
			
			a{
				font-size: 16px;
				font-weight: bold;
			}

			/* Responsive Updates ---------------------------------------------------------------*/

				// Tablet and down
				@include media-query(tablet-and-down){
					padding: 50px 15px;
				}

				// Phone
				@include media-query(phone){
					p{font-size: 15px;}
					a{font-size: 13px;}
				}
		}
	
	/* Lists ------------------------------------------------------------------------------------*/
		ul{
			margin-left: 30px;

			li + li{
				margin-top: 10px;
			}

			p + &{margin-top: 10px;}
			& + p{margin-top: 30px;}
		}
	
	/* Links ------------------------------------------------------------------------------------*/
		a:link,
		a:visited{
			color: var(--theme-primary);
			text-decoration:none;
			position: relative;
			transition: color $animation-interaction ease-in;

			&:hover,
			&:focus{
				color: var(--theme-primary-dark);
				text-decoration:none;
			}
			&:active{
				color: $color-white;
			}
		}

	/* Utility Classes --------------------------------------------------------------------------*/
		.text-error{color: $color-error;}
		.text-success{color: $color-success;}
