/*-------------------------------------------------------------------------------------------------
Footer
/*-----------------------------------------------------------------------------------------------*/
	#footer{
		position: fixed;
		width: 100%;
		bottom: 0;
		padding: 0 0 15px;
		z-index: 100;

		.container{
			display: flex;
			justify-content: flex-end;
			padding: 0 15px;

			.btn{
				animation: animateFade $animation-transition ease-in;

				// Icon Variant
				&.btn-icon{
					color: var(--theme-primary-txt);
					background: rgba(var(--theme-primary-rgb), .5);

					&:hover,
					&:focus{
						background: var(--theme-primary);
					}
				}

				// Basic structure for corners
				&:before,
				&:after{
					content: '';
					position: absolute;
					right: -5px;
					top: -5px;
					left: -5px;
					bottom: -5px;
					border: 1px solid;
					opacity: 0;
					animation: animateFade 150ms 150ms linear 5 alternate-reverse;
				}
				&:focus{
					&:before,
					&:after{opacity: 1;}
				}

				// Top/Bottom Lines
				&:before{
					border-image:
						linear-gradient(to right,
							rgba(226, 226, 226, 1) 0,
							rgba(226, 226, 226, 1) 6px,
							rgba(226, 226, 226, 0) 6px,
							rgba(226, 226, 226, 0) calc(100% - 6px),
							rgba(226, 226, 226, 1) calc(100% - 6px),
							rgba(226, 226, 226, 1) 100%
						) 1 1 100%
					;
				}

				// Left/Right Lines
				&:after{
					border-image: 
						linear-gradient(to bottom,
							rgba(226, 226, 226, 1) 0,
							rgba(226, 226, 226, 1) 6px,
							rgba(226, 226, 226, 0) 6px,
							rgba(226, 226, 226, 0) calc(100% - 6px),
							rgba(226, 226, 226, 1) calc(100% - 6px),
							rgba(226, 226, 226, 1) 100%
						) 1 100%
					;
				}

				// project filters button
				&:first-child{
					margin-right: auto;
				}

				&.hidden{
					animation: none;

					&:before,
					&:after{animation: none;}
				}
				& + .btn{margin-left: 15px;}

			}
		}

		&.isAboveModal{
			z-index: 10000;
		}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Tablet and Up
			@include media-query(tablet-and-up){
				.container{
					// make sure filter button is not visible
					.btn:nth-child(1){display: none;}
				}
			}

			// Tablet and down (adjustmnets to make buttons more clickable on mobile)
			@include media-query(tablet-and-down){
				padding: 0 0 20px;

				.container{
					.btn{
						height: 40px;
						border:3px solid rgba(0,0,0,0);

						&.btn-icon{
							width: 40px;
						}
					}
				}
			}

			// Phone
			@include media-query(phone){
				.container{padding: 0 10px;}
			}
	}
