/*-------------------------------------------------------------------------------------------------
Navigation
/*-----------------------------------------------------------------------------------------------*/
	nav{
		& + *{margin-top:40px;}

		ul{
			font-size: 18px;
			list-style: none;
			margin: 0;

			li{
				height: 30px;
				margin-top: 20px;

				&:first-child{margin-top:0;}

				a:link,
				a:visited,
				.astral-checkbox + label,
				.astral-radio + label{
					height: 30px;
					line-height: 26px;
					padding: 2px 5px;
					display: block;
					color: $color-white;
					transition: color $animation-transition ease-in;
					z-index: 10;

					&:hover,
					&:focus{
						color: $color-white;

						.icon:after{
							width: 100%;
							background: rgba($color-black, 1);
						}
					}
					&:active{ color: var(--theme-primary); }

					&.isActive{

						color: var(--theme-primary-txt);

						.icon{
							background: $color-black;
							span{color: $color-white;}

							// Background of current theme color
							&:before{
								background: linear-gradient(to right, 
									rgba(var(--theme-primary-rgb), 1) 0,
									rgba(var(--theme-primary-rgb), 0) 80%
								);
							}
								
							// Background of previous theme color
							&:after{
								width: 100%;
								opacity: 0;
								background: linear-gradient(to right, 
									rgba(var(--theme-prev-primary-rgb), 1) 0,
									rgba(var(--theme-prev-primary-rgb), 0) 80%
								);
							}
						}
					}

					/*
						In order to animate the background(which is a gradient) another psuedo-element needs to create the background to animate behind the gradient. The link's psuedo elements are taken however for focus state and in order to align things we have to float the icon to not affect the box-model since translate gives the element 'position' meaning our hover would only black out the icon.
					 */
					.icon{
						float: left;
						width: 20px;
						height: 20px;
						line-height: 26px; // firefox
						text-align: center;
						text-indent: 0;
						margin: 3px 9px 0 0;
						background: $color-gray none;
						transition: background-color $animation-interaction ease-in;

						&:before,
						&:after{
							content: '';
							position: absolute;
							top:0;left:0;right:0;bottom:0;
							z-index: -1;
						}
						&:before{
							background: linear-gradient(to right,
								rgba($color-black, 1) 0,
								rgba($color-black, 0) 80%
							);
						}
						&:after{
							width:0;
							background: rgba($color-black, 0);
							transition: background $animation-interaction ease-out, width $animation-interaction ease-out;
						}
						> span{
							font-size: 15px;
							position: relative;
							top: -3px;
							color: $color-black;

							// Icon Adjustments
							&.fa-mouse-pointer,
							&.fa-print{left:1px;}
						}
					}
				}

				// Navigation Focus Corners
				a:focus{
					outline: none;

					// Basic structure for corners
					&:before,
					&:after{
						content: '';
						position: absolute;
						right: -5px;
						top: -5px;
						left: -5px;
						bottom: -5px;
						border: 1px solid;
						animation: animateFade 150ms linear 1 alternate;
					}

					// Top/Bottom Lines
					&:before{
						border-image:
							linear-gradient(to right,
								rgba(226, 226, 226, 1) 0,
								rgba(226, 226, 226, 1) 6px,
								rgba(226, 226, 226, 0) 6px,
								rgba(226, 226, 226, 0) calc(100% - 6px),
								rgba(226, 226, 226, 1) calc(100% - 6px),
								rgba(226, 226, 226, 1) 100%
							) 1 1 100%
						;
					}

					// Left/Right Lines
					&:after{
						border-image: 
							linear-gradient(to bottom,
								rgba(226, 226, 226, 1) 0,
								rgba(226, 226, 226, 1) 6px,
								rgba(226, 226, 226, 0) 6px,
								rgba(226, 226, 226, 0) calc(100% - 6px),
								rgba(226, 226, 226, 1) calc(100% - 6px),
								rgba(226, 226, 226, 1) 100%
							) 1 100%
						;
					}
				}


				// Label Specific Differences
				.astral-checkbox,
				.astral-radio{

					& + label{
						&:before{display: none !important;}
					}

					&:checked + label{
						color: var(--theme-primary-txt);

						.icon{
							background: $color-black;
							span{color: $color-white;}

							// Background of current theme color
							&:before{
								background: linear-gradient(to right, 
									rgba(var(--theme-primary-rgb), 1) 0,
									rgba(var(--theme-primary-rgb), 0) 80%
								);
							}
								
							// Background of previous theme color
							&:after{
								width: 100%;
								opacity: 0;
								background: linear-gradient(to right, 
									rgba(var(--theme-prev-primary-rgb), 1) 0,
									rgba(var(--theme-prev-primary-rgb), 0) 80%
								);
							}
						}
					}

					&:checked + label .icon{
						&:before{
							animation: animateFade $animation-interaction;
						}
						&:after{
							background: linear-gradient(to right,
								rgba($color-black, 1) 0,
								rgba($color-black, 0) 80%
							);
							animation: animateFade $animation-interaction reverse;
						}
					}
				}

				// Hidden State
				&.isHidden{
					opacity: 0;
					height: 0;
					margin-bottom: -20px;
				}
				&.isHiding{animation: animateHideNavItem $animation-transition linear forwards;}
				&.isRevealing{animation: animateRevealNavItem $animation-transition linear forwards;}
			}
		}
	}

	/* Animations -------------------------------------------------------------------------------*/
		@keyframes animateHideNavItem{
			50%{opacity: 0;}
			100%{
				opacity: 0;
				height: 0;
				margin-bottom: -20px;
			}
		}
		@keyframes animateRevealNavItem{
			50%{
				height: 30px;
				margin-bottom: 0;
			}
			100%{
				height: 30px;
				margin-bottom: 0;
				opacity: 1;
			}
		}
