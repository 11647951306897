/*-------------------------------------------------------------------------------------------------
Templates
/*-----------------------------------------------------------------------------------------------*/
	#template{min-height: 100vh;}
	.page{
		display: none;

		&.isActive{
			display: flex;
			animation: animateFade $animation-transition ease-in;
		}

		> .sidebar{
			position: fixed;
			width: 300px;
			padding: 0 15px;
			top: 147px;
			bottom: 0;
			overflow: hidden;

			// Remove fixed for small-height devices
			@media screen and (max-height: 700px){
				position: relative;
				top: 50px;
				bottom: auto;
			}
		}
		
		> .content{
			width: 857px;
			padding: 30px 0;
			margin-left: auto;

			> *{
				margin-left:30px;
				margin-right:30px;
			}
		}

		/* Responsive Updates -------------------------------------------------------------------*/

			// Desktop
			@include media-query(desktop){
				> .content{
					width: 660px;
				}
			}

			// Tablet
			@include media-query(tablet){
				> .sidebar{
					padding: 0 10px;
					width: 250px;
				}
				> .content{
					width: 488px;

					> *{
						margin-left: 15px;
						margin-right: 15px;
					}
				}
			}

			// Phablet
			@include media-query(phablet-and-down){
				&.isActive{
					flex-direction: column;
				}

				> .sidebar{
					position: relative;
					width: 100%;
					top: auto;
					bottom: auto;
					order: 99;
					-webkit-order: 99;
					padding: 30px 15px;
				}
				> .content{
					width: 100%;
					padding-top: 20px;

					> *{
						margin-left: 15px;
						margin-right: 15px;
					}
				}
			}

			// Phone
			@include media-query(phone){
				> .content{
					width: 100%;
					padding-top: 20px;

					> *{
						margin-left: 10px;
						margin-right: 10px;
					}
				}
			}
	}

@import 'projects';
@import 'about';
@import 'resume';