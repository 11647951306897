/*-------------------------------------------------------------------------------------------------
Containers (misc div-structures)
/*-----------------------------------------------------------------------------------------------*/
	.container{
		width: $container-large;
		margin:0 auto;
		position:relative;

		/* Responsive Updates -------------------------------------------------------------------*/

			// Desktop
			@include media-query(desktop){
				width: $container-desktop;
			}

			// Tablet
			@include media-query(tablet){
				width: $container-tablet;
			}

			// Phablet
			@include media-query(phablet){
				width: calc(100% - 30px);
			}

			// Phone
			@include media-query(phone){
				width: calc(100% - 10px);
			}
	}
